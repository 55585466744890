import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as BurgerIcon } from "../../assets/img/burger.svg";
import { getSubscriberRole, getUser } from "../../redux/slices/applicationSlice";
import { fetchLogout } from "../../redux/thunks/applicationThunks";


// Reusable UserGreeting Component
const UserGreeting = ({ userName, userAvatar, className }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const ref = useRef(null);

    const closeHandler = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (open) {
            document.addEventListener('click', closeHandler)
        } else {
            document.removeEventListener('click', closeHandler)
        }

        return () => { document.removeEventListener('click', closeHandler) }
    }, [open]);

    const logout = async () => {
        const res = await dispatch(fetchLogout());
        if (res.payload.success) {
            navigate('/HOM1_1')
        }
    }

    return (
        <div className={'relative white-login-drop'} ref={ref}>
            <div onClick={handleOpen}
                className={`flex gap-2 justify-between items-center py-3 font-bold whitespace-nowrap cursor-pointer ${className ? className : ""}`}>
                <img
                    loading="lazy"
                    src={userAvatar}
                    alt="User avatar"
                    className="aspect-square w-[22px]"
                />
                <div className="grow">{`Hi, ${userName}`}</div>
            </div>

            <div className={`header-dropdown white ${open ? 'open' : ''}`}>
                <NavLink to="/SBM05_1_1"><p className={'text-center header-drop-links'}>Account</p></NavLink>

                <p onClick={logout} className={'text-center header-drop-links'}>Log Out</p>
            </div>
        </div>
    )
}

export const LoginedHeader = () => {
    const user = useSelector(getUser);
    const subscriberRole = useSelector(getSubscriberRole);
    const userRole = user?.role

    let settingsPage = "/SBM05_TEA"
    switch (subscriberRole) {
        case "admin":
            settingsPage = "/SBM05_ADM"
            break;

        case "member":
            settingsPage = "/SBM05_TEA"
            break;

        case "interviewer":
            settingsPage = "/SBM05_INT"
            break;

        default:
            break;
    }
    const loginedLinks = [
        {
            label: "Jobs",
            route: "/SBM01"
        },
        {
            label: "Jobs Setup",
            route: "/jobs-setup"
        },
        {
            label: "Support Center",
            route: "/SBM03"
        },
        {
            label: "Insights",
            route: "/SBM04"
        },
        {
            label: "Setting",
            route: settingsPage
        },

    ]

    const getFilteredLinks = () => {
        if (subscriberRole === "interviewer") {
            return loginedLinks.filter(({ route }) => route !== "/jobs-setup");
        }
        if (userRole === "support") {
            return [
                {
                    label: "Support Center",
                    route: "/SBM01"
                }
            ]
        }
        return loginedLinks;
    };

    const userName = user?.firstName;
    const userAvatar = "https://cdn.builder.io/api/v1/image/assets/TEMP/163c2d226fc8456e0b676e882ce1c6248ffa45a572786bc2c7f3da287c35a10b?apiKey=bbf94b715ef442f4991a974fece2b44a&";
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {
        document.querySelector("body").style.overflow = isMenuOpen ? "hidden" : "visible"
    }, [isMenuOpen])

    console.log("getFilteredLinks(user, loginedLinks)", getFilteredLinks(user, loginedLinks))

    return (
        <header className="flex justify-center items-center px-16 py-4 text-lg bg-white border-b border-solid border-b-zinc-300 text-zinc-800 max-md:px-5">
            <nav className="flex gap-5 justify-between w-full max-w-[1400px] max-md:flex-wrap max-md:max-w-full">
                <NavLink to="/HOM1_1" className="flex">
                    <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea67022194edab551fec1e37c423d8a4e52cf1408732c76afc3ba189db382493?apiKey=bbf94b715ef442f4991a974fece2b44a&"
                        alt="Company logo"
                        className="max-w-full aspect-[3.23] xl:w-[293px] w-36"
                    />
                </NavLink>
                <div
                    className={`
                        flex gap-5 my-auto flex-col lg:flex-row
                        max-md:max-w-full ml-[auto] lg:mr-[36px] lg:pr-[36px] max-lg:border-t lg:border-r
                        border-solid border-r-zinc-300 lg:static fixed inset-0 top-[73px]
                        transition-transform ${isMenuOpen ? "" : "-translate-x-full"} 
                        lg:translate-x-0 lg:p-0 py-7 px-4 lg:w-auto w-full bg-white lg:bg-none z-50`
                    }>
                    {getFilteredLinks(user, loginedLinks).map(({ label, route }, index) => (
                        <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? "header-link active" : "header-link")} to={route} key={index}>{label}</NavLink>
                    ))}
                    {user ?
                        <UserGreeting userName={userName} userAvatar={userAvatar} className={"lg:hidden mt-auto"} />
                        :
                        <UserGreeting userName={"John Doe"} userAvatar={userAvatar} className={"lg:hidden mt-auto"} />
                    }
                </div>
                {user ?
                    <UserGreeting userName={userName} userAvatar={userAvatar} className={"max-lg:hidden"} />
                    :
                    <UserGreeting userName={"John Doe"} userAvatar={userAvatar} className={"max-lg:hidden"} />
                }
                <div className="flex items-center gap-[10px] lg:hidden">
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="flex items-center justify-center rounded-full aspect-square h-11 border-solid border border-1 border-transparent hover:border-white hover:bg-white hover:bg-opacity-20"
                    >
                        <span className="flex items-center justify-center aspect-square w-6">
                            <BurgerIcon />
                        </span>
                    </button>
                </div>
            </nav>
        </header>
    )
}
