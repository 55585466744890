import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useParams} from "react-router-dom";
import BreadCrumbWhite from "../../../components/BreadCrumbWhite";
import PageLoader from "../../../components/PageLoader";
import {Footer} from "../../../components/parts/Footer";
import {getSubscriberRole} from "../../../redux/slices/applicationSlice";
import {getApplicationByIdFetch, getJobByIdFetch} from "../../../redux/thunks/jobsThunks";
import {ProgressBarSBM01} from "../base/ProgressBarSBM01";
import {TabsSBM01} from "../base/TabsSBM01";
import {baseCrumbs} from "../config";


export const HistoryLayout = ({isOfferLetter = false}) => {
    const subscriberRole = useSelector(getSubscriberRole);
    const {job, isLoading, application} = useSelector(state => state.jobs)
    const {jobId, applicationId} = useParams();
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(1)

    useEffect(() => {
        if (jobId && applicationId) {
            Promise.all([
                dispatch(getJobByIdFetch(jobId)),
                dispatch(getApplicationByIdFetch({applicationId, jobId}))
            ]);
        }
    }, [jobId, applicationId, dispatch]);

    const steps = [
        ["Application", `/SBM01_10_14_2_1/${jobId}/${applicationId}`],
        ["Communications", `/SBM01_10_14_2_2/${jobId}/${applicationId}`],
        ["Assessment", `/SBM01_10_14_2_3/${jobId}/${applicationId}`],
        ["Resume", `/SBM01_10_14_2_12/${jobId}/${applicationId}`]
    ]

    const crumbs = [
        ...baseCrumbs({jobId, applicationId, fullName: application?.fullName}),
        ["History", `/SBM01_10_14_2_1/${jobId}/${applicationId}`],
        activeStep ? steps[activeStep - 1] : steps[0]
    ]

    console.log("application", application)

    return (
        <>
            {!isLoading ?
                <>
                    {application?.status !== "rejected" && <BreadCrumbWhite crumbs={crumbs}/>}
                    <div className='flex-auto'>
                        <div
                            className={`flex flex-col ${application?.status !== "rejected" && "gap-[40px] md:gap-[60px]"}  max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto`}>
                            <div>
                                <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950 mb-[44px]">{application?.fullName}</h2>
                                {application?.status !== "rejected" &&
                                    <TabsSBM01 activeLink={isOfferLetter ? "offer-letter" : "history"}/>}
                            </div>

                            {!isOfferLetter && <ProgressBarSBM01 activeTab={activeStep}/>}
                            {
                                job && application && jobId && applicationId ?
                                    <Outlet context={{job, application, jobId, applicationId, setActiveStep}}/>
                                    : null
                            }
                        </div>
                        <Footer hideCopyright={true}/>
                    </div>
                </>
                : <PageLoader/>
            }
        </>
    )
}
