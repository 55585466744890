const companiesBase = 'api/companies';

export const companies = (instance) => ({
    async getCompaniesList(payload) {
        const { data } = await instance.get(`${companiesBase}/all`);
        return data;
    },
    async getAllCompanies(payload) {
        const { data } = await instance.get(`${companiesBase}`);
        return data;
    },
    async getCompanyPublicInfo(companyId, payload) {
        const { data } = await instance.get(`${companiesBase}/p/${companyId}`);
        return data;
    },
    async getCompanyInfoByMemberAccess(companyId ,payload) {
        const { data } = await instance.get(`${companiesBase}/${companyId}`);
        return data;
    },
    async updateCompanyInfo(companyId,payload) {
        const { data } = await instance.put(`${companiesBase}/${companyId}`,payload.body);
        return data;
    },
    async deleteCompany(companyId, payload) {
        const { data } = await instance.delete(`${companiesBase}/${companyId}`);
        return data;
    },


    async getAllIndustries() {
        const { data } = await instance.get(`api/industries`);
        return data;
    },
    async createDemoApplication(payload) {
        const { data } = await instance.post(`api/demo-applications`, payload)
        return data;
    },
    async getBuildCompanies(token) {
        const { data } = await instance.get(`api/build-companies/by-token/${token}`)
        return data;
    },

    async createBuildCompanies(token, payload) {
        const { data } = await instance.post(`api/build-companies/create-company/${token}`, payload)
        return data;
    },
    async createBuildCompaniesBySupport( payload) {
        const { data } = await instance.post(`api/build-companies/create-company-by-support`, payload)
        return data;
    },
    async createBuildCompaniesByAdmin( payload) {
        const { data } = await instance.post(`api/build-companies`, payload)
        return data;
    },
    async getAllCompaniesBySupport( payload) {
        const { data } = await instance.get(`api/companies/all?${payload}`)
        return data;
    },
    async getCompanyByIdSupport( payload) {
        const { data } = await instance.get(`api/companies/support/${payload}`)
        return data;
    },
    async getUpdateCompanyByIdSupport( payload) {

        console.log("payload", payload)

        const { data } = await instance.put(`api/companies/support/${payload.id}`, payload.body)
        return data;
    },
    async getDeactivateCompanyByIdSupport(payload) {
        const { data } = await instance.get(`api/users/deactivate-change/${payload.id}?isDeactivate=${payload.isDeactivate}`)
        return data;
    },

    async updateBuildCompanies(id, payload) {
        const { data } = await instance.put(`api/companies/${id}`, payload)
        return data;
    },
});

