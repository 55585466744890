import { createSlice } from '@reduxjs/toolkit';
import {
    getAllJobsFetch,
    getApplicantsFetch,
    getApplicationByIdFetch,
    getCallTokenFetch,
    getCandidatesFetch,
    getJobByIdFetch,
    getJobPipelinesListFetch,
    getJobsByCompanyFetch,
    getJobStats,
    getRejectedCandidatesFetch
} from '../thunks/jobsThunks';
import { Device } from '@twilio/voice-sdk';

const initialState = {
    jobs: [],
    job: null,
    isLoading: false,
    jobPipelines: [],
    companyJobs: [],
    jobStats: [],
    error: null,
    candidates: null,
    applicants: null,
    rejectedCandidates: null,
    pagination: null,
    candidatesPagination: null,
    application: null,
    callToken: null,
    tsid: null,
}

const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        updateApplicantTags: (state, action) => {
            if(state.applicants && state.applicants.length) {
                state.applicants = state.applicants.map(obj =>
                    obj.id === action.payload.id
                    ? {...obj, tags: action.payload.tags}
                : obj);
            } else if (state.application?.tags) {
                state.application.tags = action.payload.tags
            }
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        }
    },

    extraReducers(builder) {
        builder.addCase(getAllJobsFetch.fulfilled, (state, action) => {
            state.jobs = action.payload;
            state.isLoading = false
        });

        builder.addCase(getAllJobsFetch.pending, (state, action) => {
            state.isLoading = true
            state.jobs = null
        });

        builder.addCase(getAllJobsFetch.rejected, (state, action) => {
            state.isLoading = false
            state.jobs = null
        });

        builder.addCase(getJobsByCompanyFetch.fulfilled, (state, action) => {
            state.companyJobs = action.payload?.data;
            state.isLoading = false
            state.pagination = action.payload?.pagination
        });

        builder.addCase(getJobsByCompanyFetch.pending, (state, action) => {
            state.isLoading = true
            state.companyJobs = null
            state.pagination = null
        });

        builder.addCase(getJobsByCompanyFetch.rejected, (state, action) => {
            state.isLoading = false
            state.companyJobs = null
            state.pagination = null
        });
        builder.addCase(getJobStats.fulfilled, (state, action) => {
            state.jobStats = action.payload;
            state.isLoading = false
        });

        builder.addCase(getJobStats.pending, (state, action) => {
            state.isLoading = true
            state.jobStats = null
        });

        builder.addCase(getJobStats.rejected, (state, action) => {
            state.isLoading = false
            state.jobStats = null
        });

        builder.addCase(getJobPipelinesListFetch.fulfilled, (state, action) => {
            state.jobPipelines = action.payload
        });

        builder.addCase(getJobByIdFetch.fulfilled, (state, action) => {
            state.job = action.payload;
            state.isLoading = false
        });

        builder.addCase(getJobByIdFetch.pending, (state, action) => {
            state.isLoading = true
            state.job = null
        });

        builder.addCase(getJobByIdFetch.rejected, (state, action) => {
            state.isLoading = false
            state.job = null
            state.error = action.payload
        });

        builder.addCase(getCandidatesFetch.fulfilled, (state, action) => {
            state.candidates = action.payload.data;
            state.candidatesPagination =  action.payload.pagination;
            state.isLoading = false
        });

        builder.addCase(getCandidatesFetch.pending, (state, action) => {
            state.isLoading = true
            state.candidatesPagination = null
            state.candidates = null
        });

        builder.addCase(getCandidatesFetch.rejected, (state, action) => {
            state.isLoading = false
            state.candidatesPagination = null
            state.candidates = null
            state.error = action.payload
        });

        builder.addCase(getApplicantsFetch.fulfilled, (state, action) => {
            state.applicants = action.payload.data;
            state.candidatesPagination =  action.payload.pagination;
            state.isLoading = false
        });

        builder.addCase(getApplicantsFetch.pending, (state, action) => {
            state.isLoading = true
            state.candidatesPagination = null
            state.applicants = null
        });

        builder.addCase(getApplicantsFetch.rejected, (state, action) => {
            state.isLoading = false
            state.candidatesPagination = null
            state.applicants = null
            state.error = action.payload
        });

        builder.addCase(getRejectedCandidatesFetch.fulfilled, (state, action) => {
            state.rejectedCandidates = action?.payload?.data;
            state.candidatesPagination =  action.payload.pagination;
            state.isLoading = false
        });

        builder.addCase(getRejectedCandidatesFetch.pending, (state, action) => {
            state.isLoading = true
            state.candidatesPagination = null
            state.rejectedCandidates = null
        });

        builder.addCase(getRejectedCandidatesFetch.rejected, (state, action) => {
            state.isLoading = false
            state.candidatesPagination = null
            state.rejectedCandidates = null
            state.error = action.payload
        });

        builder.addCase(getApplicationByIdFetch.fulfilled, (state, action) => {
            state.application = action.payload;
            state.isLoading = false
        });

        builder.addCase(getApplicationByIdFetch.pending, (state, action) => {
            state.isLoading = true
            state.application = null
        });

        builder.addCase(getApplicationByIdFetch.rejected, (state, action) => {
            state.isLoading = false
            state.application = null
            state.error = action.payload
        });

        builder.addCase(getCallTokenFetch.fulfilled, (state, action) => {
            state.callToken = action.payload.token
            state.tsid = action.payload.tsid
            // const device = new Device(action.payload.token, {
            //     logLevel:1,
            //     // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            //     // providing better audio quality in restrained network conditions.
            //     codecPreferences: ["opus", "pcmu"],
            // });
            // console.log('DEVICE', device)
        });

        builder.addCase(getCallTokenFetch.pending, (state, action) => {
            state.callToken = null
            state.tsid = null
        });

        builder.addCase(getCallTokenFetch.rejected, (state, action) => {
            state.callToken = null
            state.tsid = null
            state.error = action.payload
        });
    }
})

export const {
    updateApplicantTags,
    setPagination
} = jobsSlice.actions;

export const getJobPipelinesList = (state) => state.jobs.jobPipelines;
export const getCompanyJobs = (state) => state.jobs.companyJobs;


export default jobsSlice.reducer
