import React, {useEffect, useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import {Footer} from "../components/parts/Footer";
import SelectBox from "../components/base/SelectBox";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllIndustries,
    fetchCompanyByIdSupport,
    fetchCreateCompanies, fetchDeactivateCompanyByIdSupport,
    fetchUpdateBuildCompanies, fetchUpdateCompanyByIdSupport
} from "../redux/thunks/companiesThunks";
import {getUser} from "../redux/slices/applicationSlice";
import {deactivateUser, updateUserFetch} from "../redux/thunks/applicationThunks";
import ModalTemplate from "../components/base/ModalTemplate";
import {useParams} from "react-router-dom";


const SUP1_1 = () => {
    const {id} = useParams();
    const dispatch = useDispatch()
    const account = useSelector(getUser)
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        industry: '',
        topHiringChallenges: '',
        name: '',
        // password: '',
        // cardNumber: '',
        // expirationDate: '',
        // cvv: '',
        // postalCode: '',
        // holderName: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [industryOptions, setIndustryOptions] = useState([])
    const [accInfo, setAccInfo] = useState();


    const req = async () => {
        setIsLoading(true)
        const inds = await dispatch(fetchAllIndustries())

        let indsId = ''
        const currentIndustry = account?.company?.industry?.id || account?.company?.industrieId

        if (inds?.payload && inds.payload.length > 0) {
            indsId = currentIndustry ? inds.payload.find(el => el?.id === currentIndustry) : ''
            setIndustryOptions([...inds.payload.map(el => ({label: el.name, value: el?.id}))])
        }

        if (account && !id) {
            setFormData(prev => ({
                ...prev,
                companyName: account?.company?.name,
                companySize: account?.company?.companySize,
                jobTitle: account?.company?.jobTitle,
                firstName: account.firstName,
                lastName: account.lastName,
                email: account.email,
                industry: indsId.id,
                holderName: ""
            }))
        }
        if (id) {
            const res = await dispatch(fetchCompanyByIdSupport(id)).unwrap()

            setAccInfo(res?.data)

            setFormData(prev => ({
                ...prev,
                companyName: res?.data?.name,
                companySize: res?.data?.companySize,
                jobTitle: res?.data?.jobTitle,
                firstName: res?.data?.owner?.firstName,
                lastName: res?.data?.owner?.lastName,
                email: res?.data?.owner?.email,
                industry: res?.data?.industrieId,
                holderName: ""
            }))
        }
        setIsLoading(false)
    }

    useEffect(() => {
        req()
    }, [account, id]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateCardNumber = (number) => {
        const re = /^\d{16}$/;
        return re.test(number?.replace(/\s+/g, ''));
    };

    const formatCardNumber = (e) => {
        let x = e.target.value.replace(/[^\d]/g, '');

        const formattedNumber = x.replace(/(\d{4})(?=\d)/g, '$1 ');

        setFormData(prevState => ({...prevState, 'cardNumber': formattedNumber}));
        setErrors(prevState => ({...prevState, 'cardNumber': false}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setErrors({});

        let tempErrors = {};

        if (!formData.email) {
            tempErrors.email = 'Email is required';
        } else if (!validateEmail(formData.email)) {
            tempErrors.email = 'Invalid email format';
        }

        // if (!validateCardNumber(formData.cardNumber)) {
        //     tempErrors.cardNumber = 'Invalid card number, should be 16 digits';
        // }

        let fields = [
            ['email', formData.email],
            ['firstName', formData.firstName],
            ['lastName', formData.lastName],
            ['companyName', formData.companyName],
            ['companySize', formData.companySize],
            ['jobTitle', formData.jobTitle],
            ['industry', formData.industry]
        ];


        fields.forEach(([key, value]) => {
            if (!value) {
                tempErrors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required`;
            }
        });

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            setIsLoading(false)
            return;
        }

        if (!id) {
            await dispatch(updateUserFetch({
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName
            }))

            if (account?.company) {
                await dispatch(fetchUpdateBuildCompanies({
                    name: formData.companyName,
                    jobTitle: formData.jobTitle,
                    industrieId: formData.industry,
                    companySize: formData.companySize
                    , id: account?.company?.id
                }))
            }
        } else {
            await dispatch(fetchUpdateCompanyByIdSupport({
                id: id,
                body: {
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    name: formData.companyName,
                    jobTitle: formData.jobTitle,
                    industrieId: formData.industry,
                    companySize: formData.companySize,
                }
            }))

        }

        setIsLoading(false)
    };


    const handleDeactivate = async (e) => {
        await dispatch(deactivateUser())

        setConfirm(false)
    };

    const deactivateHandler = async () => {
        if (!id) {
            setConfirm(true)
        } else {
            await dispatch(fetchDeactivateCompanyByIdSupport({
                id: accInfo?.owner?.id,
                isDeactivate: !accInfo?.owner?.isDeactivated
            }))
            req()
        }
    }

    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Account Page']}/>

            <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1240px] w-full m-auto flex-1">
                <div className="flex flex-col justify-center bg-white px-4">
                    {
                        !isLoading ?
                            <section
                                className="flex flex-col justify-between items-center px-8 lg:px-16 py-9 lg:py-[60px] bg-white rounded-md shadow-[0_5px_25px_-10px_rgba(0,0,0,0.15)] lg:mt-[24px]">
                                <form className="w-full" onSubmit={handleSubmit}>
                                    <div className="grid md:grid-cols-2 gap-x-[60px]">
                                        <div>
                                            <InputField
                                                label="Work Email"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="email" name="email" id="email"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.email} onChange={handleChange} error={errors.email}
                                            />
                                            <InputField
                                                label="First Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="firstName" id="firstName" placeholder="First name"
                                                value={formData.firstName} onChange={handleChange}
                                                error={errors.firstName}
                                            />
                                            <InputField
                                                label="Last Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="lastName" id="lastName" placeholder="Last name"
                                                value={formData.lastName} onChange={handleChange}
                                                error={errors.lastName}
                                            />
                                            <InputField
                                                label="Company Name"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="companyName" id="companyName" placeholder="Company name"
                                                value={formData.companyName} onChange={handleChange}
                                                error={errors.companyName}
                                                disabled={!account?.company && !id}
                                            />
                                            <SelectField
                                                label="Company Size"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                name="companySize"
                                                id="companySize"
                                                value={formData.companySize}
                                                onChange={handleChange}
                                                options={[
                                                    {value: '1-10', label: '1-10 Employees'},
                                                    {value: '11-50', label: '11-50 Employees'},
                                                    {value: '51-200', label: '51-200 Employees'},
                                                ]}
                                                placeholder="Select company size"
                                                disabled={!account?.company && !id}
                                            />
                                            {errors.companySize &&
                                                <span className="text-red-500 mb-[18px]">{errors.companySize}</span>}
                                            <div className="grid lg:grid-cols-2 gap-x-5">
                                                <div>
                                                    <InputField
                                                        label="Job Title"
                                                        inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                        name="jobTitle" id="jobTitle" placeholder="Job title"
                                                        value={formData.jobTitle} onChange={handleChange}
                                                        disabled={!account?.company && !id}
                                                    />
                                                    {errors.jobTitle &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.jobTitle}</span>}
                                                </div>
                                                <div>
                                                    <SelectBox
                                                        options={industryOptions}
                                                        label="Industry"
                                                        onSelect={val => {
                                                            setFormData(prevState => ({...prevState, 'industry': val}))
                                                        }}
                                                        className={'!mt-[10px] mb-[18px]'}
                                                        value={formData?.industry} svg={2}
                                                        placeholder={formData?.industry}
                                                        disabled={!account?.company && !id}
                                                    />
                                                    {errors.industry &&
                                                        <span
                                                            className="text-red-500 mb-[18px]">{errors.industry}</span>}
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="flex flex-col gap-[16px] flex-1 max-lg:mt-[24px] max-lg:mb-[36px] mb-[52px]">
                                                <label className="text-[18px] font-bold text-zinc-800 leading-[18px]">Top
                                                    Hiring Challenges</label>
                                                <ul className="text-[18px] leading-[22px] list-disc ml-[18px]">
                                                    <li className="mb-[13px]">Plan Level 01</li>
                                                    <li className="mb-[13px]">Plan Level 02</li>
                                                    <li>Plan Level 03</li>
                                                </ul>
                                            </div>

                                            <label
                                                className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-[16px] block">Credit
                                                Card Info</label>
                                            <InputField
                                                label="Card Number"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                                type="text" name="cardNumber" id="cardNumber"
                                                placeholder="0000-0000-0000-0000"
                                                value={formData.cardNumber}
                                                max='19'
                                                onChange={formatCardNumber}
                                                error={errors.cardNumber}
                                            />
                                            <div className="grid lg:grid-cols-3 gap-x-5 pb-[0px]">
                                                <InputField
                                                    label="Expiration Date"
                                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                    labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                                    type="date" name="expirationDate" id="expirationDate"
                                                    placeholder="11/30/2023"
                                                    value={formData.expirationDate} onChange={handleChange}
                                                />
                                                <InputField
                                                    label="CVV"
                                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                    labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                                    type="text" name="cvv" id="cvv" placeholder="000"
                                                    value={formData.cvv}
                                                    onChange={handleChange}
                                                    max='3'
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                                                    }}
                                                />
                                                <InputField
                                                    label="Postal Code"
                                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                    labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                                    type="text" name="postalCode" id="postalCode" placeholder="123456"
                                                    value={formData.postalCode} onChange={handleChange}
                                                />
                                            </div>
                                            <div className="pb-[0px]">
                                                <InputField
                                                    label="Card Holder Name"
                                                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                    labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
                                                    name="holderName" id="holderName" placeholder="John Doe"
                                                    value={formData.holderName} onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex justify-center items-center max-md:flex-col gap-[22px] mt-[24px]">
                                        <button
                                            className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
                                            Save
                                        </button>
                                        <button type={"button"} onClick={() => deactivateHandler()}
                                                className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md ${accInfo?.owner?.isDeactivated ? "bg-[#37B34A]" : "bg-[#D70100]"} max-md:px-5 hover:opacity-70`}>
                                            {accInfo?.owner?.isDeactivated ? "Activate" : "Deactivate"}
                                        </button>
                                    </div>
                                </form>
                                {confirm && <ModalTemplate open={confirm} onClick={() => setConfirm(false)}
                                                           modalSize={'modal__dialog--600'}>
                                    <div className="modal__body gap-[32px]">
                                        <h1 className='text-[56px] font-bold uppercase'>Warning!</h1>
                                        <div className="shrink-0 h-1 bg-green-500 w-[266px]"/>
                                        <p>By deactivating your account, you will lose access to your profile, data, and
                                            all associated services. This action is permanent and cannot be undone.</p>
                                        <p> If you're sure, please confirm below.</p>
                                        <button onClick={() => handleDeactivate()}
                                                className="flex text-[18px] font-bold justify-center items-center py-[13px] px-[20px] bg-green-500 text-white min-w-[122px] rounded-md max-md:flex-1 hover:opacity-70">
                                            Confirm
                                        </button>
                                    </div>
                                </ModalTemplate>}
                            </section>
                            :
                            <div className='text-4xl md:text-5xl font-bold text-sky-950 text-center'>Load
                                information</div>
                    }
                </div>
            </main>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SUP1_1;


// import React, { useEffect, useState } from "react";
// import BreadCrumbWhite from "../components/BreadCrumbWhite";
// import InputField from "../components/base/InputField";
// import SelectField from "../components/base/SelectField";
// import { Footer } from "../components/parts/Footer";
// import SelectBox from "../components/base/SelectBox";
// import { useDispatch } from "react-redux";
// import { fetchAllIndustries } from "../redux/thunks/companiesThunks";
//
//
// const SUP1_1 = () => {
//     const dispatch = useDispatch()
//     const [formData, setFormData] = useState({
//         email: '',
//         firstName: '',
//         lastName: '',
//         companyName: '',
//         companySize: '',
//         jobTitle: '',
//         industry: '',
//         topHiringChallenges: '',
//         name: '',
//         password: '',
//         cardNumber: '',
//         expirationDate: '',
//         cvv: '',
//         postalCode: '',
//     });
//
//     const [errors, setErrors] = useState({});
//
//     const [isLoading, setIsLoading] = useState(false);
//     const [industryOptions, setIndustryOptions] = useState([
//         { label: "Select industry", value: '' }
//     ])
//
//     useEffect(() => {
//         const req = async () => {
//
//             setIsLoading(true)
//
//             const inds = await dispatch(fetchAllIndustries())
//
//             // const account = await dispatch(fetchBuildCompanies(token))?.payload
//             const account = {
//                 companyName: "McDonald",
//                 companySize: 100,
//                 jobTitle: "Chemical Free Home Cleaning Technician",
//                 firstName: "Donald",
//                 lastName: "McDonald",
//                 email: "youremailaddress@mail.com",
//                 industry: 'Maid Service / House Cleaning',
//                 cardNumber: '0000000000000000',
//                 cardExpirationDate: '11.12.2016',
//                 cardCVV: '000',
//                 cardCompanyName: "companyName",
//                 postalCode: '0000000000000000'
//             }
//
//             let indsId = ''
//             const currentIndustry = account.industry
//
//             if (inds?.payload && inds.payload.length > 0) {
//                 indsId = currentIndustry ? inds.payload.find(el => el.name === currentIndustry)?.id : ''
//                 setIndustryOptions([{ label: "Select industry", value: '' }, ...inds.payload.map(el => ({ label: el.name, value: el.id }))])
//             }
//
//             if (account) {
//                 setFormData(prev => ({
//                     ...prev,
//                     companyName: account.companyName,
//                     companySize: account.companySize,
//                     jobTitle: account.jobTitle,
//                     firstName: account.firstName,
//                     lastName: account.lastName,
//                     email: account.email,
//                     industry: indsId,
//                 }))
//
//
//
//             }
//             setIsLoading(false)
//         }
//
//         req()
//
//
//     }, []);
//
//
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData(prevState => ({ ...prevState, [name]: value }));
//     };
//
//     const validateEmail = (email) => {
//         const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return re.test(email);
//     };
//
//     const validateCardNumber = (number) => {
//         const re = /^\d{16}$/;
//         return re.test(number.replace(/\s+/g, ''));
//     };
//
//     const formatCardNumber = (e) => {
//         let x = e.target.value.replace(/[^\d]/g, '');
//
//         const formattedNumber = x.replace(/(\d{4})(?=\d)/g, '$1 ');
//
//         setFormData(prevState => ({...prevState, 'cardNumber': formattedNumber}));
//         setErrors(prevState => ({...prevState, 'cardNumber': false}));
//     };
//
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Reset errors
//         setErrors({});
//
//         let tempErrors = {};
//
//         if (!validateEmail(formData.email)) {
//             tempErrors.email = 'Invalid email format';
//         }
//
//         if (!validateCardNumber(formData.cardNumber)) {
//             tempErrors.cardNumber = 'Invalid card number, should be 16 digits';
//         }
//
//         // Here you can extend the validations for other fields as needed
//
//         if (Object.keys(tempErrors).length > 0) {
//             setErrors(tempErrors);
//         } else {
//             // Submit your form
//             console.log('Form data:', formData);
//         }
//     };
//
//     // const breadcrumbItems = [
//     //     { name: 'Home', to: "/", isCurrent: false },
//     //     { name: 'Support', to: "/", isCurrent: true }
//     // ];
//
//     return (
//         <>
//             <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], 'Account Page']} />
//
//             <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1240px] w-full m-auto flex-1">
//                 <div className="flex flex-col justify-center bg-white px-4">
//                     {
//                         !isLoading ?
//                             <section className="flex flex-col justify-between items-center px-8 lg:px-16 py-9 lg:py-[60px] bg-white rounded-md shadow-[0_5px_25px_-10px_rgba(0,0,0,0.15)] lg:mt-[24px]">
//                                 <form className="w-full" onSubmit={handleSubmit}>
//                                     <div className="grid md:grid-cols-2 gap-x-[60px]">
//                                         <div>
//                                             <InputField
//                                                 label="Work Email" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                 type="email" name="email" id="email" placeholder="youremailaddress@mail.com"
//                                                 value={formData.email} onChange={handleChange} error={errors.email}
//                                             />
//                                             <InputField
//                                                 label="First Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                 name="firstName" id="firstName" placeholder="First name"
//                                                 value={formData.firstName} onChange={handleChange}
//                                             />
//                                             <InputField
//                                                 label="Last Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                 name="lastName" id="lastName" placeholder="Last name"
//                                                 value={formData.lastName} onChange={handleChange}
//                                             />
//                                             <InputField
//                                                 label="Company Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                 name="companyName" id="companyName" placeholder="Company name"
//                                                 value={formData.companyName} onChange={handleChange}
//                                             />
//                                             {/* <SelectField
//                                                 label="Company Size"
//                                                 inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                 name="companySize"
//                                                 id="companySize"
//                                                 value={formData.companySize}
//                                                 onChange={handleChange}
//                                                 options={[
//                                                     { value: '1-10', label: '1-10 Employees' },
//                                                     { value: '11-50', label: '11-50 Employees' },
//                                                     { value: '51-200', label: '51-200 Employees' },
//                                                     // Add more options here
//                                                 ]}
//                                                 placeholder="Select company size"
//                                             /> */}
//                                             <InputField label="Company Size" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" name="companySize" type='number' id="companySize"
//                                                 value={formData.companySize} onChange={handleChange} />
//                                             <div className="grid lg:grid-cols-2 gap-x-5">
//                                                 <InputField
//                                                     label="Job Title" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                     name="jobTitle" id="jobTitle" placeholder="Job title"
//                                                     value={formData.jobTitle} onChange={handleChange}
//                                                 />
//                                                 {/* <SelectField
//                                                     label="Industry"
//                                                     inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
//                                                     name="industry"
//                                                     id="industry"
//                                                     value={formData.industry}
//                                                     onChange={handleChange}
//                                                     options={industries}
//                                                     placeholder="Maid Service"
//                                                 /> */}
//                                                 <SelectBox options={industryOptions} label="Industry"
//                                                     onSelect={val => {
//                                                         setFormData(prevState => ({ ...prevState, 'industry': val }))
//
//                                                     }}
//                                                     className={'!mt-[10px]'}
//                                                     value={formData.industry} svg={2}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div>
//                                             <div className="flex flex-col gap-[16px] flex-1 max-lg:mt-[24px] max-lg:mb-[36px] mb-[52px]">
//                                                 <label className="text-[18px] font-bold text-zinc-800 leading-[18px]">Top Hiring Challenges</label>
//                                                 <ul className="text-[18px] leading-[22px] list-disc ml-[18px]">
//                                                     <li className="mb-[13px]">Plan Level 01</li>
//                                                     <li className="mb-[13px]">Plan Level 02</li>
//                                                     <li>Plan Level 03</li>
//                                                 </ul>
//                                             </div>
//
//                                             <label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-[16px] block">Credit Card Info</label>
//                                             <InputField
//                                                 label="Card Number" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
//                                                 type="text" name="cardNumber" id="cardNumber" placeholder="0000-0000-0000-0000"
//                                                 value={formData.cardNumber}
//                                                 max='19'
//                                                 onChange={formatCardNumber}
//                                                 error={errors.cardNumber}
//                                             />
//                                             <div className="grid lg:grid-cols-3 gap-x-5 pb-[0px]">
//                                                 <InputField
//                                                     label="Expiration Date" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
//                                                     type="date" name="expirationDate" id="expirationDate" placeholder="11/30/2023"
//                                                     value={formData.expirationDate} onChange={handleChange}
//                                                 />
//                                                 <InputField
//                                                     label="CVV" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
//                                                     type="text" name="cvv" id="cvv" placeholder="000"
//                                                     value={formData.cvv}
//                                                     onChange={handleChange}
//                                                     max='3'
//                                                     onInput={(e) => {
//                                                       e.target.value = e.target.value.replace(/[^0-9]/g, "")
//                                                     }}
//                                                 />
//                                                 <InputField
//                                                     label="Postal Code" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
//                                                     type="text" name="postalCode" id="postalCode" placeholder="123456"
//                                                     value={formData.postalCode} onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="pb-[0px]">
//                                                 <InputField
//                                                     label="Company Name" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"
//                                                     name="name" id="name" placeholder="John Doe"
//                                                     value={formData.name} onChange={handleChange}
//                                                 />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="flex justify-center items-center max-md:flex-col gap-[22px] mt-[24px]">
//                                         <button className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
//                                             Save
//                                         </button>
//                                         <button className={`min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#D70100] max-md:px-5 hover:opacity-70`}>
//                                             Deactivate
//                                         </button>
//                                     </div>
//                                 </form>
//                             </section>
//                             :
//                             <div className='text-4xl md:text-5xl font-bold text-sky-950 text-center'>Load information</div>
//                     }
//                 </div>
//             </main>
//             <Footer hideCopyright={true} />
//         </>
//     );
// };
//
// export default SUP1_1;
