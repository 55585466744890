import {useEffect, useState} from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import api from '../api'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentPage} from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import {
    fetchAllIndustries,
    fetchBuildCompanies,
    fetchCreateBuildCompanies, fetchCreateBuildCompaniesByAdmin,
    fetchCreateBuildCompaniesBySupport
} from "../redux/thunks/companiesThunks";
import * as React from "react";
import SelectBox from "../components/base/SelectBox";
import Confirmation from "../components/Confirmation";
import PageLoader from "../components/PageLoader";
import {toast} from "react-toastify";

const SUP1_5 = ({children}) => {
    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    let [searchParams] = useSearchParams();
    const token = searchParams.get('token')

    const [message, setMessage] = useState(null)

    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        companySize: '',
        jobTitle: '',
        industry: '',
        login: '',
        password: '',
        // cardNumber: '',
        // expirationDate: '',
        // cvv: '',
        // postalCode: '',
    });

    const [industryOptions, setIndustryOptions] = React.useState([])

    const [companySizeOptions, setCompanySizeOptions] = useState([
        {value: '1-10', label: '1-10 Employees'},
        {value: '11-50', label: '11-50 Employees'},
        {value: '51-200', label: '51-200 Employees'},
    ]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pricingPlan, setPricingPlan] = React.useState({isDemo: false})
    const [errors, setErrors] = useState({});
    const [agree, setAgree] = useState(false);


    const getAllIndustries = async () => {
        const inds = await dispatch(fetchAllIndustries());
        setIndustryOptions([...inds.payload.map(el => ({
            label: el.name,
            value: el.id
        }))]);
    };

    useEffect(() => {
        getAllIndustries();
    }, [])


    useEffect(() => {
        if (!token) return;

        const req = async () => {
            setIsLoading(true);
            const res = await dispatch(fetchBuildCompanies(token));

            let indsId = '';
            if (industryOptions && industryOptions.length > 0) {
                indsId = res?.payload?.industry ? industryOptions.find(el => el.name === res?.payload?.industry)?.id : '';
            }

            if (res.payload) {
                const data = res.payload;
                setFormData(prev => ({
                    ...prev,
                    companyName: data.companyName,
                    companySize: companySizeOptions.find(el => el.value === data.companySize) || '',
                    jobTitle: data.jobTitle,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    industry: indsId,
                    login: data.email,
                }));
                data.pricingPlan && setPricingPlan(data.pricingPlan);
            }

            setIsLoading(false);
        };

        req();
    }, [token, companySizeOptions]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
        setErrors(prevState => ({...prevState, [name]: false}))
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateCardNumber = (number) => {
        const re = /^\d{16}$/;
        return re.test(number?.replace(/\s+/g, ''));
    };
    const formatCardNumber = (e) => {
        let x = e.target.value?.replace(/[^\d]/g, '');

        const formattedNumber = x?.replace(/(\d{4})(?=\d)/g, '$1 ');

        setFormData(prevState => ({...prevState, 'cardNumber': formattedNumber}));
        setErrors(prevState => ({...prevState, 'cardNumber': false}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!agree) {
            toast.error("Please agree to the terms and conditions");
            return;
        }

        setErrors({});
        let tempErrors = {};

        const fields = Object.entries(formData);
        fields.forEach(([key, value]) => {
            if (!value) {
                tempErrors[key] = "This field is required";
            }
        });

        if (!validateEmail(formData.email)) {
            tempErrors.email = 'Invalid email format';
        }

        // if (!validateCardNumber(formData.cardNumber)) {
        //     tempErrors.cardNumber = 'Invalid card number, should be 16 digits';
        // }

        if (Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
            return;
        }

        try {
            let req;
            if (!token) {
                req = await dispatch(fetchCreateBuildCompaniesBySupport({
                    ...formData,
                }));
            } else {
                req = await dispatch(fetchCreateBuildCompanies({
                    ...formData,
                    token: token,
                }));
            }


            if (!req?.payload?.success) {
                toast.error(`${req.payload.message}.  ${req.payload.message.includes("not found") ? "Please click the link in the email" : ""}`);
                return
            }

            if (req?.payload?.message) {
                toast.success(req.payload.message);
            }
        } catch (err) {
            toast.error("Error submitting the form.");
        }
    };
    const breadcrumbItems = [
        {name: 'Home', to: "/", isCurrent: false},
        {name: 'Support', to: "/sup01", isCurrent: false},
        {name: 'Create Company', to: "/sup1_5", isCurrent: true}
    ];


    return (<>
        <Breadcrumbs links={breadcrumbItems}/>

        {!isLoading && !message ? <section
            className="flex flex-col justify-between items-center px-8 md:px-16 py-9 md:py-11 bg-white rounded-md shadow-lg max-w-md:px-5 mt-[24px]">
            <header className="text-4xl md:text-5xl font-bold text-sky-950">
                {/*{pricingPlan.title || 'Demo Plan'}: $9900 / Year*/}
                {pricingPlan.title || 'Create Company'}
            </header>
            <form className="md:mt-11 mt-9 w-full" onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 gap-y-9 gap-x-[60px]">
                    <div>
                        <InputField label={getValue('form_work_email') || "Work Email"} type="email" name="email"
                                    id="email" value={formData.email}
                                    onChange={handleChange} error={errors.email}/>
                        <InputField label={getValue('form_first_name') || "First Name"} name="firstName" id="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange} error={errors.firstName}/>
                        <InputField label={getValue('form_last_name') || "Last Name"} name="lastName" id="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange} error={errors.lastName}/>
                        <InputField label={getValue('form_company_name') || "Company Name"} name="companyName"
                                    id="companyName"
                                    value={formData.companyName} onChange={handleChange} error={errors.companyName}/>
                        <SelectBox options={companySizeOptions} label={getValue('form_company_size') || "Company Size"}
                                   onSelect={val => {
                                       setFormData(prevState => ({...prevState, 'companySize': val}))
                                       setErrors(prevState => ({...prevState, 'companySize': false}));

                                   }}
                                   className={'!mt-[10px] !mb-[16px]'}
                                   value={formData.companySize} svg={2}
                                   error={errors.companySize}
                                   placeholder={"Choose Company Size"}
                        />
                        <div className="grid lg:grid-cols-2 gap-x-5 !mt-[18px]">
                            <InputField label={getValue('form_job_title') || "Job Title"} name="jobTitle" id="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange} error={errors.jobTitle}/>
                            <SelectBox options={industryOptions} label={getValue('form_industry') || "Industry"}
                                       onSelect={val => {
                                           setFormData(prevState => ({...prevState, 'industry': val}))
                                           setErrors(prevState => ({...prevState, 'industry': false}));

                                       }}
                                       className={'!mt-[10px] !mb-[16px]'}
                                       value={formData.industry} svg={2}
                                       error={errors.industry}
                                       placeholder={"Choose Industry"}
                            />
                        </div>
                    </div>
                    <div>
                        {pricingPlan.features && <div className="flex flex-col gap-[16px] flex-1 mb-9">
                            {pricingPlan.features.split('\n').map((el, idx) => {
                                if (idx === 0) {
                                    return <label className="md:text-[18px] font-bold text-zinc-800 leading-[18px]">
                                        {el}</label>
                                }
                            })}

                            <ul className="text-[18px] leading-[22px] list-disc ml-[18px] top-hiring-plans">

                                {pricingPlan.features.split('\n').map((el, idx) => {
                                    if (idx === 0) {
                                        return (<></>)
                                    } else {
                                        return <li>{el}</li>
                                    }
                                })}
                            </ul>
                        </div>}
                        <div className="md:pb-[16px]">
                            <InputField label="Login" name="login" id="name" value={formData.login} error={errors.login}
                                        onChange={handleChange}/>
                        </div>
                        <div>
                            <InputField label="Password" name="password" id="password" type="password"
                                        error={errors.password} value={formData.password} onChange={handleChange}/>
                        </div>
                        {/*<label className="text-[18px] font-bold text-zinc-800 leading-[18px] mb-[16px] block">Credit*/}
                        {/*    Card Info</label>*/}
                        {/*<InputField*/}
                        {/*    label="Card Number" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"*/}
                        {/*    type="text" name="cardNumber" id="cardNumber"*/}
                        {/*    max='19'*/}
                        {/*    value={formData.cardNumber} onChange={formatCardNumber} error={errors.cardNumber}*/}
                        {/*/>*/}
                        {/*<div className="grid grid-cols-1 lg:grid-cols-3 gap-x-5">*/}
                        {/*    <InputField*/}
                        {/*        error={errors.expirationDate}*/}
                        {/*        label="Expiration Date"*/}
                        {/*        labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"*/}
                        {/*        type="date" name="expirationDate" id="expirationDate"*/}
                        {/*        value={formData.expirationDate} onChange={handleChange}*/}
                        {/*    />*/}
                        {/*    <InputField*/}
                        {/*        max='3'*/}
                        {/*        label="CVV" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"*/}
                        {/*        type="text" name="cvv" id="cvv"*/}
                        {/*        error={errors.cvv}*/}
                        {/*        value={formData.cvv}*/}
                        {/*        onInput={(e) => {*/}
                        {/*            e.target.value = e.target.value?.replace(/[^0-9]/g, "")*/}
                        {/*        }}*/}
                        {/*        onChange={handleChange}*/}
                        {/*    />*/}
                        {/*    <InputField*/}
                        {/*        label="Postal Code" labelClassName="text-[18px] font-[400] text-zinc-800 leading-[18px]"*/}
                        {/*        type="text" name="postalCode" id="postalCode"*/}
                        {/*        value={formData.postalCode} error={errors.postalCode} onChange={handleChange}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="flex items-center">*/}
                        {/*    <b className="uppercase text-[16px] text-[#333333] mr-[8px]">charge amount:</b>*/}
                        {/*    <b className="uppercase text-[30px] text-[#002E67]">$9900</b>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="flex justify-center flex-col items-center">
                    <div className="flex justify-center mt-8">
                        <input
                            type="checkbox"
                            id="agreement"
                            className="hidden"
                            onChange={(e) => {
                                setAgree(e.target.checked);
                            }}
                            checked={agree}
                        />
                        <label htmlFor="agreement" className="flex items-center cursor-pointer gap-5 md:gap-4">
                        <span
                            className={`flex h-[28px] w-[58px] items-center rounded-full aspect-square p-1 transition-all ${agree ? 'bg-[#37B34A]' : 'bg-gray-300'}`}
                            aria-hidden="true"
                        >
                            <span
                                className={`h-[20px] w-[20px] rounded-full transition-all ${agree ? 'ml-[30px] bg-white' : 'bg-[#37B34A]'}`}
                            ></span>
                        </span>
                            <span className="ml-2 text-[16px] text-zinc-800 flex-1 max-md:leading-[22px]">
                            By selecting this, you agree to the
                            <a href="/HOM_1_12" className="text-blue-500"> Privacy Policy </a> &
                            <a href="/HOM_1_11" className="text-blue-500"> Terms of Services</a>
                        </span>
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="mt-5 h-[60px] md:h-16 text-[19px] md:text-2xl font-bold text-white uppercase bg-[#37B34A] rounded-md px-[5px] md:px-[65px] hover:bg-[#002E67] justify-center max-md:w-full inline-flex items-center"
                        // onClick={(e) => {
                        // 	e.preventDefault();
                        // 	submit(e)
                        // }}
                    >
                        {getValue('form_button_text_complete') || 'Hire better and faster!'}
                    </button>
                </div>
            </form>
        </section> : null}
        {!message && isLoading &&
            <PageLoader color="light"/>
        }
        {message && <Confirmation colorStyle="white" text={message}/>}
        {children ? children : null}


    </>);
}

export default SUP1_5;
