import { useEffect, useState } from 'react';
import api from '../../api';
import { ReactComponent as EditIcon } from "../../assets/img/bx-edit.svg";
import SelectField from "../../components/base/SelectField";
import ContactUsLinkBlock from '../../components/parts/ContactUsLinkBlock';
import { AboutFormSection } from "../SBM02_5/AboutFormSection";
import { AboutTitleSection } from "../SBM02_5/AboutTitleSection";
import { AvailabilityFormSection } from "../SBM02_5/AvailabilityFormSection";
import { ConclusionSection } from "../SBM02_5/ConclusionSection";
import { ConsentSection } from "../SBM02_5/ConsentSection";
import { CriticalRequirementsSection } from "../SBM02_5/CriticalRequirementsSection";
import { DateFormSection } from "../SBM02_5/DateFormSection";
import { DescriptionSection } from "../SBM02_5/DescriptionSection";
import { DurationSection } from "../SBM02_5/DurationSection";
import { EmergencyContactForm } from "../SBM02_5/EmergencyContactForm";
import { EmployersCountSection } from "../SBM02_5/EmployersCountSection";
import { EmploymentHistorySection } from "../SBM02_5/EmploymentHistorySection";
import { InputSection } from "../SBM02_5/InputSection";
import { JobsCountSection } from "../SBM02_5/JobsCountSection";
import { RadioSection } from "../SBM02_5/RadioSection";
import { ReferalSection } from "../SBM02_5/ReferalSection";
import { RequirementsSection } from "../SBM02_5/RequirementsSection";
import { ResumeUploadSection } from "../SBM02_5/ResumeUploadSection";
import { TermsSection } from "../SBM02_5/TermsSection";


const Step7 = ({ setContactFormOpen, payload, setPayload, bottomPaste = null, }) => {
    const [interviewQuestions, setInterviewQuestions] = useState([])

    const getCriticalRequirement = async () => {
        let res = await api.quantitativeAnalysis.getInterviewQuestions("Application Questions");
        if (res?.success) {
            setInterviewQuestions(res.data)
        }
    }

    console.log("interviewQuestions", interviewQuestions)

    useEffect(() => {
        getCriticalRequirement()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col max-md:gap-[8px]'>
                <span className='text-[40px] font-bold max-md:text-[36px]'>Let’s Get Started</span>
                <span className='text-[18px] max-md:text-[16px] leading-[30px] max-md:leading-[22px]'>These details serve as the foundation of your job.</span>
            </div>

            <div className='w-full max-w-[1026px] mx-auto'>
                {payload.applicationForm ? <div className='rounded-md border border-solid border-[#D6D6D6] lg:pt-[44px] lg:px-[44px] lg:pb-[70px] lg:gap-[44px] flex flex-col
                    py-[36px] px-[32px] gap-[32px] '>
                    <div className='border-b border-solid border-[#D6D6D6] lg:pb-[44px] pb-[32px] lg:text-[30px] text-[28px] leading-[36px] font-semibold text-[#333333]'>
                        Application Form
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] lg:pb-[44px] pb-[32px]'>
                        <div className='flex lg:items-center lg:justify-between flex-col lg:flex-row lg:gap-0 gap-6'>
                            <div className='flex items-center justify-between  gap-[12px] lg:text-[30px] text-[24px] leading-[36px] font-bold text-[#333333]'>
                                {payload.applicationForm.it_takes_15_minutes_title || "It takes 15 minutes"}
                                {/*<button>*/}
                                {/*    <EditIcon className='w-[28px] h-[28px]' />*/}
                                {/*</button>*/}
                            </div>
                            <SelectField
                                parentClass='w-[258px] flex-none'
                                placeholder={'Active'}
                                inputBg="bg-white rounded-md border border-solid border-[#DEDED9] gray-arrow"
                                name="it_takes_15_minutes_active"
                                id="it_takes_15_minutes_active"
                                value={payload.applicationForm.it_takes_15_minutes_active}
                                onChange={handleChange}
                                options={[
                                    { value: true, label: "Active" },
                                    { value: false, label: "Not Active" },
                                ]}
                            />
                        </div>
                        {/*<p className='mt-[16px] text-[18px] leading-[36px] font-semibold text-[#37B34A] underline underline-offset-[3px]'>Recommend Defult Setup</p>*/}
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            {/* It takes X minutes */}
                            <DescriptionSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* What you need: */}
                            <RequirementsSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* How long it takes? */}
                            <DurationSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            {/* Consent */}
                            <ConsentSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* Are you able to legally work in the United State? */}
                            <RadioSection
                                payload={payload}
                                setPayload={setPayload}
                                fields={{
                                    active: "are_you_able_to_legally_work_in_the_united_state_active",
                                    title: "are_you_able_to_legally_work_in_the_united_state_title",
                                    choices: "are_you_able_to_legally_work_in_the_united_state_choices",
                                }}
                            />
                            {/* How did you hear about this job? */}
                            <ReferalSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* Please review the following Terms, Disclosures and Consent */}
                            <TermsSection
                                payload={payload}
                                setPayload={setPayload}
                                disableTerms={false}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px] max-md:gap-[24px]'>
                            {/* You! Yes, You! */}
                            <AboutTitleSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* Education */}
                            <AboutFormSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            {/* Most Recent Employment History */}
                            <EmploymentHistorySection
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <RadioSection
                                payload={payload}
                                setPayload={setPayload}
                                fields={{
                                    active: "may_we_contact_your_previous_supervisor_for_reference_active",
                                    title: "may_we_contact_your_previous_supervisor_for_reference_title",
                                    choices: "may_we_contact_your_previous_supervisor_for_reference_choices",
                                }}
                            />
                            {/* What is the maximum number of employers an applicant can enter? */}
                            <EmployersCountSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            {/* Name of Emergency Contact */}
                            <EmergencyContactForm
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            {/* Do you have any relative who is an employee of this company? */}
                            <RadioSection
                                className={"max-w-[450px]"}
                                inputClassName={"h-[54px]"}
                                payload={payload}
                                setPayload={setPayload}
                                fields={{
                                    active: "do_you_have_any_relative_who_is_an_employee_of_this_company_active",
                                    title: "do_you_have_any_relative_who_is_an_employee_of_this_company_title",
                                    choices: "do_you_have_any_relative_who_is_an_employee_of_this_company_choices",
                                    inputLabel: "do_you_have_any_relative_who_is_an_employee_of_this_company_if_so_who"
                                }}
                            />
                            {/* Did someone refer you to the position? */}
                            <RadioSection
                                className={"max-w-[450px]"}
                                inputClassName={"h-[54px]"}
                                payload={payload}
                                setPayload={setPayload}
                                fields={{
                                    active: "did_someone_refer_you_to_the_position_active",
                                    title: "did_someone_refer_you_to_the_position_title",
                                    choices: "did_someone_refer_you_to_the_position_choices",
                                    inputLabel: "did_someone_refer_you_to_the_position_if_so_who"
                                }}
                            />
                            <InputSection
                                activeField="did_someone_refer_you_to_the_position_the_source_you_heard_about_this_job_active"
                                titleField="did_someone_refer_you_to_the_position_the_source_you_heard_about_this_job"
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <JobsCountSection
                                payload={payload}
                                setPayload={setPayload}
                            />

                            <RadioSection
                                className={"max-w-[450px]"}
                                // title={"Have you ever been terminated from a job?"}
                                // inputLabel="If so, who?"
                                labelClassName={"font-normal"}
                                inputClassName={"min-h-[100px]"}

                                payload={payload}
                                setPayload={setPayload}
                                fields={{
                                    active: "have_you_ever_been_terminated_from_a_job_active",
                                    title: "have_you_ever_been_terminated_from_a_job_title",
                                    choices: "have_you_ever_been_terminated_from_a_job_choices",
                                    inputLabel: "have_you_ever_been_terminated_from_a_job_if_so_please_explain"
                                }}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <CriticalRequirementsSection
                                payload={payload}
                                questions={interviewQuestions}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <AvailabilityFormSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                            <DateFormSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <ResumeUploadSection
                                payload={payload}
                                setPayload={setPayload}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-8'>
                        <ConclusionSection
                            payload={payload}
                            setPayload={setPayload}
                        />

                        <ContactUsLinkBlock
                            setContactFormOpen={setContactFormOpen}
                            hr={false}
                            topPaste={bottomPaste}
                        />
                    </div>
                </div> : null}
            </div>
        </>
    )
}

export default Step7
