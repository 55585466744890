import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {toast} from 'react-toastify';
import api from '../../../api';
import InputFieldHorisontal from '../../../components/base/InputFieldHorisontal';
import MultiSelect from '../../../components/base/MultiSelect';
import PrimaryButton from '../../../components/base/PrimaryButton';
import PageLoader from '../../../components/PageLoader';
import {EvaluationInterview} from "../../../components/parts/EvaluationsInterview";
import {getApplicationByIdFetch, getJobByIdFetch} from '../../../redux/thunks/jobsThunks';
import {QuestionTypes} from '../../AP_01_Form/parts/questionTypes';
import {NextStepSectionSBM01} from '../base/NextStepSectionSBM01';
import {getSubscriberRole} from "../../../redux/slices/applicationSlice";
import getImageUrl from "../../../assets/helpers/getImageUrl";
import videoPlaceholder from "../../../assets/img/video-placeholder.png";
import FileInputField from "../../../components/base/FileInputField";
import InputField from "../../../components/base/InputField";

const calculateTotalScore = (clarifications, answersScores, maxScore = 7) => {
    const statuses = [
        "Completed",
        "On-going",
        "Not Started",
    ];

    if (!clarifications?.length || !answersScores?.length) return {};

    const totalQuestions = clarifications.length;

    const totalQuestionsAnswered = answersScores.filter(score => !isNaN(parseFloat(score))).length;

    const totalScoreSum = answersScores.reduce((sum, score) => !isNaN(parseFloat(score)) ? sum + parseFloat(score) : sum, 0);

    const maxPossibleTotalScore = totalQuestionsAnswered * maxScore;

    const totalPercentage = totalQuestionsAnswered > 0
        ? Math.round((totalScoreSum / maxPossibleTotalScore) * 100)
        : 0;

    let totalStatus = statuses[2];
    if (totalQuestionsAnswered === totalQuestions) {
        totalStatus = statuses[0];
    } else if (totalQuestionsAnswered > 0) {
        totalStatus = statuses[1];
    }

    return {
        total: {
            score: totalScoreSum,
            percentage: totalPercentage,
            status: totalStatus,
            name: "Total",
            key: "total"
        }
    };
};


const Score = ({title = "Score", onChange, value, disabled}) => (
    <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
        <div
            className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
            {title}
        </div>
        <InputFieldHorisontal
            className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
            error={null}
            min={0}
            max={10}
            type="number"
            onChange={onChange}
            disabled={disabled}
            value={parseInt(value) || null}
        />
    </div>
);

const InterviewSection = ({
                              questions,
                              companyId,
                              job = null,
                              selectedInterviewers = [],
                              handleSelect = () => {
                              }
                          }) => {
    const [interviewers, setInterviewers] = useState([]);

    useEffect(() => {
        if (!companyId) return;
        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success || !response?.data?.length) {
                return;
            }
            setInterviewers(response.data.map(member => ({
                value: member?.id,
                label: `${member?.user?.firstName} ${member?.user?.lastName}`
            })))
        })
    }, [companyId])

    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                {/*2<sup>nd</sup> Interview*/}
                {job?.pipeline?.id === 1 ? "In Person Interview" : "Virtual Interview"}
            </div>
            <div
                className="self-stretch mt-9 max-md:mt-[24px] w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full mb-9"/>
            {/* <div className="flex max-lg:flex-col justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full mb-9">
                <div className="my-auto lg:text-lg font-semibold text-zinc-800">
                    List of recruiter names
                </div>
                <div className='select-multi-value-container--secondary w-full max-w-[815px]'>
                    <MultiSelect
                        selectStyle="secondary w-full"
                        placeholder='Add a recruiter '
                        value={selectedInterviewers}
                        onChange={handleSelect}
                        options={interviewers}
                    />
                </div>
            </div> */}
            {questions}
        </div>
    );
};


const SecondInterview = () => {
    const navigate = useNavigate()
    const {job, application, jobId, applicationId, setActiveStep} = useOutletContext()
    useEffect(() => {
        setActiveStep(4)
    }, [])
    const [videoInterview, setVideoInterview] = useState(null)
    const [inteviewDetails, setInteviewDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const subscriberRole = useSelector(getSubscriberRole);


    const [answersScores, setAnswersScores] = useState([])
    const [transcript, setTranscript] = useState([])
    const [answersConfig, setAnswersConfig] = useState({})
    const [questions, setQuestions] = useState([])
    const [totalScore, setTotalScore] = useState(null);

    // const questionsData = job && application
    // 	? job.applicationForm.critical_requirements_of_the_job_questions
    // 		.map((item, index) => ({ ...item, answerValue: application.applicationForm.critical_requirements_of_the_job_answers[index] }))
    // 		.filter(item => item.questionCode.includes('IP'))
    // 	: [];
    // const filter = job.applicationForm.critical_requirements_of_the_job_questions.filter(item => item.questionCode.includes('IP'))
    // const questionsData = job && application && filter?.length ? filter : questions;
    const questionsData = questions;

    const initialAnswers = questionsData.map(question => ({
        question,
        answer: ''
    }))

    const [answers, setAnswers] = useState(application?.criticalRequirements?.["2nd-interview"] || initialAnswers)
    const [selectedInterviewers, setSelectedInterviewers] = useState(application?.assessments?.["2nd-interview"]?.listRecruiter || [])
    // const statusColors = {
    // 	'pending': 'yellow-500',
    // 	'completed': 'green-500'
    // }


    const getQuestions = async () => {
        setIsLoading(true);
        try {
            const res = await api.jobVideoInterview.getInterviewQuestions('In Person Interview');

            if (!res.success) {
                toast.error(res?.message || "An error occurred while fetching the interview questions");
            }


            //need remove this filter for questions
            if (res?.data) {
                const validQuestionIds = [83, 151, 85, 86, 87, 88];

                const filteredQuestions = res.data.filter(question =>
                    validQuestionIds.includes(question.id)
                );

                const question151 = filteredQuestions.find(q => q.id === 151);
                const otherQuestions = filteredQuestions.filter(q => q.id !== 151);

                const reorderedQuestions = [
                    otherQuestions[0],
                    question151,
                    ...otherQuestions.slice(1)
                ];

                setQuestions(reorderedQuestions);

                setAnswers(
                    application?.criticalRequirements?.["2nd-interview"] ||
                    reorderedQuestions.map(question => ({
                        question,
                        answer: ''
                    }))
                );
            }
            //need remove this filter for questions

            // setQuestions(res.data)
            // setAnswers(application?.criticalRequirements?.["2nd-interview"] || res.data.map(question => ({
            //     question,
            //     answer: ''
            // })))

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getQuestions()
    }, [])


    useEffect(() => {
        setIsLoading(true)
        api.jobVideoInterview.getVideoInterview({
            jobId,
            type: '2nd-interview'
        }).then(resp => {
            if (resp.success) {
                setVideoInterview(resp.data)
                const videoId = resp.data.id
                api.jobVideoInterview.getListVideoInterview(applicationId).then(resp => {
                    if (!resp.success || !resp.data.length) {
                        setIsLoading(false)
                        return
                    }
                    const interviewItem = resp.data.find(item => item.jobVideoInterview.id === videoId && item.jobVideoInterview.type === '2nd-interview')
                    if (!interviewItem) {
                        setIsLoading(false)
                        return
                    }
                    api.jobVideoInterview.getInterviewDetails({
                        jobApplicationId: applicationId,
                        interviewId: interviewItem.id
                    }).then(response => {
                        setIsLoading(false)

                        if (!response.success) return
                        setInteviewDetails(response.data)
                    })
                })
            } else {
                setIsLoading(false)
            }
        })

    }, [jobId, applicationId])


    useEffect(() => {
        const details = application?.criticalRequirements?.["2nd-interview"]
        const qualityQuestions = inteviewDetails
            ? inteviewDetails?.qualityQuestions
            : details?.map(item => ({qualityAttribute: item?.question?.qualityAttribute}))

        if (!qualityQuestions?.length) return

        const obj = {}
        const arr = qualityQuestions.reduce((res, curr, index) => {
            const key = res.find(r => r.key === curr?.qualityAttribute?.key)
            const score = {...curr, score: answersScores?.[index]}
            if (key) {
                key.stack.push(score)
            } else {
                res.push({
                    key: curr?.qualityAttribute?.key,
                    stack: [score],
                    name: curr?.qualityAttribute?.name,
                })
            }
            return res
        }, []).map(item => {
            let avg = (item?.stack || []).reduce((r, it) => r + parseFloat(it.score), 0) / (item?.stack || []).length;
            return {...item, avg};
        });
        arr?.forEach((item) => {
            obj[item?.key] = {
                score: item?.avg,
                name: item?.name,
                key: item?.key
            }
        })
        setAnswersConfig(obj)
    }, [application, answersScores, inteviewDetails])

    useEffect(() => {
        setTranscript(application?.assessments?.["2nd-interview"]?.transcript)
        setAnswersScores(
            application?.assessments?.["2nd-interview"]?.scores ||
            Array.from({length: inteviewDetails ? inteviewDetails?.questions?.length : questionsData?.length || 0}).fill("NONE"))
    }, [application, inteviewDetails])

    const changeAnswerHandler = (index, val, key) => {
        // const updatedAnswers = [...answers]
        // updatedAnswers[index].answer = val
        // setAnswers(updatedAnswers)

        setAnswers((prev) => prev.map((item, itemIndex) => index === itemIndex ? {...item, answer: val} : item))
    }
    const changeAnswerScoreHandler = (index, val, key) => {
        const copy = [...answersScores]
        copy[index] = val
        setAnswersScores(copy)
    }

    const handlePrev = () => {
        navigate(`/SBM01_10_2/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
    }
    // const [loading, setLoading] = useState(false);

    // const handleSendInterview = async () => {
    // 	setLoading(true)
    // 	const response = await api.jobVideoInterview.sendVideoInterview({
    // 		applicationId,
    // 		interviewId: videoInterview.id
    // 	})
    // 	setLoading(false)
    // 	toast(response.message, {
    // 		type: response.success ? 'success' : 'error'
    // 	})
    // }


    // const sendToAIRecomendationRequestFetch = async () => {
    // 	const body = {
    // 		"type": "2nd_interview" // 'clarification', '1st_interview', '2nd_interview'
    // 	}
    // 	try {
    // 		const res = await api.jobApplications.sendToAIRecomendationRequest({ applicationId, body })
    // 		if (res) {
    // 			console.log(res);
    // 		}
    // 	} catch (error) {
    // 		console.log(error);
    // 	}
    // }

    const dispatch = useDispatch()

    const handleSave = async () => {
        if (answersScores.some(score => score > 7 || score < 0)) {
            toast.error("Scores must be from 0 to 7")
            return
        }

        const scores = new Array(application?.criticalRequirements?.["2nd-interview"]?.length)?.fill(null)?.map((one, index) => answersScores?.[index] || one)

        setIsLoading(true)
        try {
            const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
                assessments: {
                    ...application?.assessments || {},
                    "2nd-interview": {
                        ...application?.assessments?.["2nd-interview"],
                        scores: scores,
                        listRecruiter: selectedInterviewers
                    }
                },
                criticalRequirements: {
                    "2nd-interview": answers
                },

                // ...(videoInterview
                // 	? {}
                // 	: {
                // 		criticalRequirements: {
                // 			"2nd-interview": answers
                // 		},
                // 	}
                // )
            })
            if (resp.success) {
                Promise.all([
                    dispatch(getJobByIdFetch(jobId)),
                    dispatch(getApplicationByIdFetch({applicationId, jobId}))
                ]);
                toast.success("Saved")
            } else {
                toast.error(resp.message || "Something went wrong")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        setIsLoading(false)
    }


    useEffect(() => {
        const clarifications = application?.criticalRequirements?.["2nd-interview"] || [];
        const total = calculateTotalScore(clarifications, answersScores);
        setTotalScore(total);
    }, [answersScores, application]);

    const [video, setVideo] = useState()
    const [videoLoading, setVideoLoading] = useState(false)

    useEffect(() => {
        if (application.inPersonVideoURL) {
            setVideo(application.inPersonVideoURL)
        }
    }, [application]);

    const handleSaveVideo = async (type = "save", file) => {

        setVideoLoading(true)
        if (type === "remove") {
            setVideo(null)
            try {
                const response = await api.jobApplicationDocuments.uploadPersonVideo({
                    jobId: jobId,
                    applicationId: applicationId,
                    params: "?deleteVideo=true",
                    body: {}
                });

                if (!response.success) {
                    toast.error("Failed to save video.");
                    setVideoLoading(false)
                    return;
                }

                toast.success("Remove Successfully");
                setVideoLoading(false)
            } catch (error) {
                console.error("Error uploading video:", error);
            }
        }

        if (type === "save" && file) {
            setVideo(file)
            try {
                const formData = new FormData();
                formData.append('inPersonVideoFile', file);

                const response = await api.jobApplicationDocuments.uploadPersonVideo({
                    jobId: jobId,
                    applicationId: applicationId,
                    body: formData
                });

                if (!response.success) {
                    toast.error("Failed to save video.");
                    setVideoLoading(false)
                    return;
                }

                setVideoLoading(false)
                toast.success("Saved Successfully");
            } catch (error) {
                console.error("Error uploading video:", error);
                toast.error("An error occurred while uploading the video.");
            }
        }
        setVideoLoading(false)
    };


    const [payload, setPayload] = useState({
        date: "",
        startTime: "",
        endTime: "",
        title: "",
    });

    const [errors, setErrors] = useState({
        date: "",
        startTime: "",
        endTime: "",
        title: "",
    });

    const [meeting, setMeeting] = useState();
    const [meetingLoader, setMeetingLoader] = useState(false);


    const getMeetings = async () => {
        const response = await api.jobApplications.getCalendarMeetings({applicationId});
        if (response.success) {
            setMeeting(response.data?.[0]);
        }
    };

    const createMeeting = async () => {
        setMeetingLoader(true);
        const {date, startTime, endTime, title} = payload;

        const newErrors = {};
        const now = new Date();

        if (!date) newErrors.date = "Date is required.";
        if (!startTime) newErrors.startTime = "Start time is required.";
        if (!endTime) newErrors.endTime = "End time is required.";

        if (date && startTime && endTime) {
            const startDateTime = new Date(`${date}T${startTime}`);
            const endDateTime = new Date(`${date}T${endTime}`);

            if (startDateTime < now) newErrors.date = "Start time cannot be in the past.";
            if (startDateTime >= endDateTime) newErrors.endTime = "End time must be after the start time.";

            const maxDuration = 60 * 60 * 1000;
            if (endDateTime - startDateTime > maxDuration) {
                newErrors.endTime = "Meeting duration cannot exceed 1 hour.";
            }
        }

        if (!title.trim()) newErrors.title = "Title is required.";

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            setMeetingLoader(false)
            return;
        }

        const data = {
            type: "meeting",
            key: "virtual_interview",
            title: title.trim() || "Virtual Interview",
            startDate: new Date(`${date}T${startTime}`).toISOString(),
            endDate: new Date(`${date}T${endTime}`).toISOString(),
        };

        try {
            const response = await api.jobApplications.createCalendarMeeting({
                applicationId,
                body: data,
            });
            if (!response.success) {
                toast.error(response.message);
                setMeetingLoader(false)
                return;
            } else {
                toast.success("Meeting created successfully!");
                getMeetings();
            }
            setMeetingLoader(false)
        } catch (error) {
            console.error("Error creating meeting:", error);
        }
    };


    const handleChange = (field, value) => {
        setPayload((prev) => ({
            ...prev,
            [field]: value,
        }));
        setErrors((prev) => ({
            ...prev,
            [field]: "",
        }));
    };

    const cancelMeeting = async () => {
        try {
            const response = await api.jobApplications.canselCalendarMeeting({
                applicationId,
                meetingId: meeting.id,
            });
            if (!response.success) {
                toast.error(response.message);
                return;
            } else {
                toast.success(response.message);
                getMeetings()
            }

        } catch (error) {
            console.error("Error creating meeting:", error);
        }
    };

    useEffect(() => {
        if (job?.pipeline?.id === 2) {
            getMeetings()
        }
    }, [applicationId, job]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(meeting.payload.hangoutLink)
            .then(() => toast.success('Link copied to clipboard!'))
    };


    console.log("job", job)

    return (
        <div className='flex-auto'>
            <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>

                {job?.pipeline?.id === 2 && subscriberRole !== "interviewer" && (
                    <div className="mb-6">
                        {meeting && (
                            <>
                                <div
                                    className="bg-white border border-solid border-[#DEDED9] p-6 rounded-md flex justify-between items-center"
                                >
                                    <div>
                                        <h2 className="text-lg font-bold text-gray-800 mb-2">{meeting.title}</h2>
                                        {new Date(meeting.endDate) < new Date() && <div>
                                            <span className="text-yellow-300 font-bold text-lg">Completed</span>
                                        </div>}
                                        <div className="my-2">
                                            <strong className="text-gray-600">Start Date:</strong>{" "}
                                            <span>{new Date(meeting.startDate).toLocaleString()}</span>
                                        </div>
                                        <div className="mb-2">
                                            <strong className="text-gray-600">End Date:</strong>{" "}
                                            <span>{new Date(meeting.endDate).toLocaleString()}</span>
                                        </div>
                                        {new Date(meeting.endDate) > new Date() &&
                                            <div className="mb-2 flex items-center gap-3">
                                                <strong className="text-gray-600">Meeting Link:</strong>
                                                <a
                                                    href={meeting.payload.hangoutLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 underline"
                                                >
                                                    {meeting.payload.hangoutLink}
                                                </a>

                                                <PrimaryButton
                                                    onClick={handleCopyLink}
                                                    className="btn btn-secondary !h-fit p-1 !text-[10px]"
                                                >
                                                    Copy Link
                                                </PrimaryButton>
                                            </div>}
                                    </div>

                                    <div className="flex justify-end self-end gap-5">
                                        {new Date(meeting.endDate) > new Date() &&
                                            <PrimaryButton
                                                onClick={() => window.open(meeting.payload.hangoutLink, "_blank")}
                                                className="justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"
                                            >
                                                Go to Meeting
                                            </PrimaryButton>
                                        }
                                        <PrimaryButton
                                            onClick={cancelMeeting}
                                            className="justify-center bg-red-500 ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"
                                        >
                                            Remove
                                        </PrimaryButton>
                                    </div>
                                </div>


                                {meeting?.payload?.records?.length &&
                                    <div>
                                        <p
                                            className='my-6 text-2xl font-bold leading-8 text-zinc-800'>Meeting
                                            Record</p>
                                        {
                                            meeting?.payload?.records.map(one =>
                                                <div>
                                                    <video width="auto" height="auto" controls
                                                           className='max-h-[500px]'>
                                                        <source
                                                            src={`${process.env.REACT_APP_API_URL}google-meet/${meeting.payload.records[0]}`}
                                                            type="video/mp4"/>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            )}
                                    </div>
                                }
                            </>
                        )}


                        {!meeting && <>
                            <div>
                                <h2 className="mt-8 text-xl font-bold text-zinc-800">Title</h2>
                                <InputField
                                    type="text"
                                    inputBg="bg-white border border-solid border-[#DEDED9] mt-3.5"
                                    value={payload.title}
                                    onChange={(e) => handleChange("title", e.target.value)}
                                />
                                {errors.title && <p className="text-red-600 mt-1">{errors.title}</p>}
                            </div>
                            <div className="flex gap-5 justify-between items-center">
                                <div className="flex gap-5">
                                    <div>
                                        <h2 className="mt-8 text-xl font-bold text-zinc-800">Date</h2>
                                        <InputField
                                            type="date"
                                            inputBg="bg-white border border-solid border-[#DEDED9] mt-3.5"
                                            value={payload.date}
                                            onChange={(e) => handleChange("date", e.target.value)}
                                        />
                                        {errors.date && <p className="text-red-600 mt-1">{errors.date}</p>}
                                    </div>

                                    <div>
                                        <h2 className="mt-8 text-xl font-bold text-zinc-800">Start Time</h2>
                                        <InputField
                                            type="time"
                                            inputBg="bg-white border border-solid border-[#DEDED9] mt-3.5"
                                            value={payload.startTime}
                                            onChange={(e) => handleChange("startTime", e.target.value)}
                                        />
                                        {errors.startTime && <p className="text-red-600 mt-1">{errors.startTime}</p>}
                                    </div>

                                    <div>
                                        <h2 className="mt-8 text-xl font-bold text-zinc-800">End Time</h2>
                                        <InputField
                                            type="time"
                                            inputBg="bg-white border border-solid border-[#DEDED9] mt-3.5"
                                            value={payload.endTime}
                                            max={payload.startTime}
                                            onChange={(e) => handleChange("endTime", e.target.value)}
                                        />
                                        {errors.endTime && <p className="text-red-600 mt-1">{errors.endTime}</p>}
                                    </div>
                                </div>
                                <div className="flex justify-end w-full my-6">
                                    <PrimaryButton
                                        onClick={createMeeting}
                                        isLoading={meetingLoader}
                                        className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                                    >
                                        Create Meeting
                                    </PrimaryButton>
                                </div>

                            </div>
                        </>}
                    </div>
                )}


                {job.pipeline.id === 1 && subscriberRole !== "interviewer" && <div className='mb-6'>
                    <div className='flex justify-between gap-6 mb-2'>
                        <label className='block mb-2'>Intro Video</label>
                        {video && <PrimaryButton onClick={() => handleSaveVideo("remove")} type="button"
                                                 className={"bg-red-500 !h-8 px-4"}>Remove</PrimaryButton>}
                    </div>
                    {video &&
                        <video className='w-full aspect-video mb-4'
                               src={(getImageUrl(video))}
                               controls
                        />
                    }
                    <FileInputField
                        hideActions={true}
                        record={true}
                        boxClassName='!max-w-full'
                        acceptProp={{'video/*': ['.mp4', '.avi']}}
                        handleChange={(file) => setVideo(file)}
                        isVideo
                        handleRemove={() => handleSaveVideo("remove")}
                        fileProp={video}
                    />

                    <div className="flex justify-end w-full my-6">
                        <PrimaryButton
                            onClick={() => handleSaveVideo("save", video)}
                            isLoading={videoLoading}
                            className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                        >
                            Save
                        </PrimaryButton>
                    </div>

                </div>}


                {/* {
					isLoading
						? <PageLoader />
						: videoInterview
							? (
								<div>
									<div className="flex justify-end w-full mb-6">
										<PrimaryButton
											onClick={sendToAIRecomendationRequestFetch}
											className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
										>
											AI Video agent
										</PrimaryButton>
									</div>
									<div className='flex items-center justify-between'>
										<div className="text-3xl font-bold leading-8 text-zinc-800">
											<p>2<sup>nd</sup> Video Interview</p>
										</div>
										{inteviewDetails ? <p>Status: <span className={`text-${statusColors[inteviewDetails.status]}`}>{inteviewDetails.status}</span></p> :
											<PrimaryButton onClick={handleSendInterview} className='px-6'>
												{loading ? <PageLoader color='light' className={'h-[40px]'} /> : 'Send Video Interview to Candidate'}
											</PrimaryButton>}

									</div>
									<p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>{videoInterview?.name}</p>
									{videoInterview?.intro && <><p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>Intro</p>
										<video width="auto" height="auto" controls className='max-h-[600px]'>
											<source src={videoInterview?.intro?.url} type="video/mp4" />
											Your browser does not support the video tag.
										</video></>}
									<p className='my-8 text-2xl font-bold leading-8 text-zinc-800'>Questions</p>
									{
										inteviewDetails
											?
											<ol className='flex flex-col gap-6'>
												{inteviewDetails?.candidateHireflix?.questions.map((item, index) => {
													const currentQuestion = inteviewDetails?.qualityQuestions?.[index]
													return <div key={index}>
														<div className='flex flex-col gap-2'>
															<h4 className='text-2xl font-bold leading-8 text-zinc-800'>{index + 1}. {item.title}</h4>
															<p className='text-xl'>{item.description}</p>
															<p className='text-xl'>{currentQuestion?.question}</p>
														</div>
														<div className='flex items-center gap-8 mt-4'>
															{item?.media?.url && <div>
																<p className='text-xl font-bold leading-8 text-zinc-800'>Question: </p>
																<video width="auto" height="auto" controls className='max-h-[300px]'>
																	<source src={item?.media?.url} type="video/mp4" />
																	Your browser does not support the video tag.
																</video>
															</div>}
															{item?.answer?.url && <div>
																<p className='text-xl font-bold leading-8 text-zinc-800'>Answer: </p>
																<video width="auto" height="auto" controls className='max-h-[300px]'>
																	<source src={item?.answer?.url} type="video/mp4" />
																	Your browser does not support the video tag.
																</video>
															</div>}
														</div>
														<Score
															onChange={(e => changeAnswerHandler(index, e.target.value, currentQuestion?.qualityAttribute?.key))}
															value={parseInt(answersScores[index]) || null}
														/>
													</div>
												})}
											</ol>
											:
											<ol className='flex flex-col gap-4'>
												{videoInterview?.questions.map((item, index) => <div>
													<p className='text-xl font-bold leading-8 text-zinc-800'>{index + 1}. {item.title}</p>
													<p>{item.description}</p>
												</div>)}
											</ol>
									}
									{videoInterview?.outro?.title &&
										<p className='my-6 text-2xl font-bold leading-8 text-zinc-800'>{videoInterview?.outro?.title}</p>}
									{videoInterview?.outro?.description &&
										<p className='mb-2 text-xl leading-8 text-zinc-800'>{videoInterview?.outro?.description}</p>}
									{videoInterview?.outro?.media?.url && <video width="auto" height="auto" controls className='max-h-[600px]'>
										<source src={videoInterview?.outro?.media?.url} type="video/mp4" />
										Your browser does not support the video tag.
									</video>}
								</div>
							)
							: (
								<div>
									<div className='flex justify-end w-full'>
										{!application?.criticalRequirements?.["2nd-interview"]
											&& (
												<p>Status: <span className={`text-yellow-500`}>Pending</span></p>
											)
										}
									</div>
									<InterviewSection
										companyId={job?.companyId}
										questions={
											<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
												{answers.map((item, index) => {
													const Question = QuestionTypes["text"];
													if (!Question) return null
													return (
														<div className='flex flex-col'>
															<Question
																key={item.question.id}
																error={null}
																config={item.question}
																number={index + 1}
																onChange={val => setAnswers((prev) => prev.map((item, itemIndex) => index === itemIndex ? { ...item, answer: val } : item))}
																value={item.answer || null}
																className="mt-[0px]"
																labelClassName="!text-[16px] !leading-[20px]" />
															<Score
																onChange={(e => changeAnswerHandler(index, e.target.value))}
																value={parseInt(answersScores[index]) || null}
															/>
														</div>
													)
												})}
											</div>
										}
									/>
								</div>
							)

				} */}

                {
                    !isLoading ? (
                        <div>
                            <div className='flex justify-end w-full'>
                                {/* {!application?.criticalRequirements?.["2nd-interview"]
									&& (
										<p>Status: <span className={`text-yellow-500`}>Pending</span></p>
									)
								} */}
                            </div>
                            <InterviewSection
                                companyId={job?.companyId}
                                job={job}
                                selectedInterviewers={selectedInterviewers}
                                handleSelect={setSelectedInterviewers}
                                questions={
                                    <div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
                                        {answers.map((item, index) => {
                                            const Question = QuestionTypes["text"];
                                            if (!Question) return null
                                            return (
                                                <div className='flex flex-col'>
                                                    <Question
                                                        key={item.question.id}
                                                        error={null}
                                                        config={item.question}
                                                        number={index + 1}
                                                        onChange={val => changeAnswerHandler(index, val)}
                                                        value={item.answer || null}
                                                        className="mt-[0px]"
                                                        disabled={subscriberRole === "interviewer"}
                                                        labelClassName="!text-[16px] !leading-[20px]"/>
                                                    <Score
                                                        onChange={(e => changeAnswerScoreHandler(index, e.target.value))}
                                                        value={parseInt(answersScores[index]) || null}
                                                        disabled={subscriberRole === "interviewer"}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            />
                        </div>
                    ) : <PageLoader/>
                }

                <EvaluationInterview transcript={transcript} config={answersConfig} totalOnly
                                     propsTotal={totalScore?.total?.percentage}/>
                {subscriberRole !== "interviewer" &&
                    <div className="flex justify-end w-full my-6">
                        <PrimaryButton
                            onClick={handleSave}
                            className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
                        >
                            Save
                        </PrimaryButton>
                    </div>}
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext}/>
        </div>
    )
}

export default SecondInterview
