import { useSelector } from "react-redux";
import {HiringProcess} from "../../../components/base/ProgressStepsSBM";
import React, {useEffect, useState} from "react";
import { getSubscriberRole } from "../../../redux/slices/applicationSlice";

export const HiringProcessSBM01 = ({activeTab = 1}) => {
    const subscriberRole = useSelector(getSubscriberRole)

    const steps = [
        { stepNumber: "1", label: "Clarifications", isActive: false },
        { stepNumber: "2", label: "Video Interview", isActive: false },
        { stepNumber: "3", label: "Post Interview\nSurvey", isActive: false },
        { stepNumber: "4", label: "2nd Interview", isActive: false },
        { stepNumber: "5", label: "Document\nReview", isActive: false },
        { stepNumber: "6", label: "Rating of\nThe Job", isActive: false },
        { stepNumber: "7", label: "AI\nRecommendation", isActive: false },
        { stepNumber: "8", label: "Final Hiring Decision", isActive: false },
        { stepNumber: "9", label: "Training Insights", isActive: false },
        { stepNumber: "10", label: "Offer Meeting", isActive: false },
        { stepNumber: "11", label: "Training\nMilestones", isActive: false },
    ];

    const [modifyStep, setModifyStep] = useState(null)

    useEffect(() => {
        let modifiedStep = steps.map((step, index) => {
            if (step.stepNumber <= activeTab) {
                step.isActive = true
                return step
            } else {
                return step
            }
        })
        if(subscriberRole === "interviewer" && modifiedStep.length) {
            modifiedStep = modifiedStep.slice(0, 4)
        }
        setModifyStep(modifiedStep)
    }, []);

    return (
        <section className="flex w-full text-center overflow-auto">
            {modifyStep && <HiringProcess stepsData={modifyStep}/>}
        </section>
    )
}
