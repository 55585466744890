import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useNavigation} from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {Footer} from "../components/parts/Footer";
import SupportFilters from "../components/parts/SupportFilters";
import {useDispatch, useSelector} from "react-redux";
import {fetchSupportSolutionsPages, fetchSupSolCategories} from "../redux/thunks/supportThunks";
import {fetchPublicSolutionsPages, fetchPubSolCategories, fetchPubSolTags} from "../redux/thunks/publicThunks";
import {getPublicSolutionsPages} from "../redux/slices/publicSolutionsSlice";
import {getJobsByCompanyFetch} from "../redux/thunks/jobsThunks";
import {fetchAllCompaniesBySupport} from "../redux/thunks/companiesThunks";
import {Pagination} from "./SBM01_2";
import PageLoader from "../components/PageLoader";
import {format} from "date-fns";

function SubscriptionTracker({data}) {
    const navigate = useNavigate()

    const handleClick = (id) => {
        navigate("/SUP1_1/" + id)
    }

    return (
        <>
            <div className="w-full flex flex-col gap-7 mt-9 lg:hidden">
                {data?.map((post, index) => (
                    <div className="flex flex-col gap-3" key={index}>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Company Name</div>
                            {/*<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post?.name}</div>*/}
                        </div>
                        <div className="flex items-baseline justify-between gap-4">
                            <div className="text-base font-bold w-2/5 text-wrap text-[#333333]">Date Subscription
                                Started
                            </div>
                            {/*<div*/}
                            {/*    className="text-lg text-[#003578] font-bold text-wrap text-right">{post?.createdAt || new Date()}</div> */}
                        </div>
                    </div>
                ))}
            </div>
            <div className="overflow-x-auto max-lg:hidden">
                <table className="w-full mt-8">
                    <thead className="text-3xl text-zinc-800">
                    <tr>
                        <th className="px-5 py-2 text-left">Company Name</th>
                        <th className="px-5 py-2 text-center">Date Subscription Started</th>
                    </tr>
                    </thead>
                    <tbody className="text-2xl text-sky-900">
                    {data?.map((subscription, index) => (
                        <tr
                            key={index}
                            onClick={() => handleClick(subscription?.id)}
                            className="cursor-pointer"
                        >
                            <td className="px-5 py-2 text-left">
                                <div className="flex items-center gap-[20px]">
                                    <div className="w-[12px] h-[12px] bg-[#37B34A]"/>
                                    <div className="font-[700] text-[26px]">{subscription?.name}</div>
                                </div>
                            </td>
                            <td className="px-5 py-2 text-center font-[700] text-[26px]">{format(subscription?.createdAt || new Date(), "MMMM dd yyyy")}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function PostsPage() {
    const [params, setParams] = useState({})
    const [companies, setCompanies] = useState([])
    const [pagination, setPagination] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getCompanies = async () => {
        setIsLoading(true)
        const res = await dispatch(fetchAllCompaniesBySupport(`industrieId=${params?.industry || ""}&search=${params?.search || ""}&page=${params?.page || 1}`)).unwrap();
        setCompanies(res?.data || [])
        setPagination(res?.pagination || null)
        setIsLoading(false)
    };

    useEffect(() => {
        getCompanies()
    }, [params]);


    return (
        <section
            className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800 max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
                <h1 className="text-4xl font-bold max-md:text-center">Current Subscribers</h1>
                <p className="mt-2 text-center max-md:max-w-full">Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit.</p>
                <div
                    className="flex flex-col self-stretch py-9 px-8 lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                    <div className="flex items-center justify-between gap-5">
                        <SupportFilters
                            setParams={setParams}
                            industry
                            // location
                            // status
                            search
                        />
                        <button onClick={() => navigate(`/SUP1_5`)}
                                className="  min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[54px] md:h-[54px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70">
                            Create
                        </button>
                    </div>
                    <div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full"/>
                    {!isLoading ?

                        companies.length ?
                            <div>
                                <SubscriptionTracker data={companies}/>

                            </div>
                            :
                            <div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No
                                Subscribers
                                yet</div>
                        :
                        <PageLoader/>}
                </div>
                {pagination && pagination?.maxPages >= 2 && <Pagination
                    currentPage={pagination.page}
                    totalPages={pagination.maxPages}
                    onPageChange={page => setParams({...params, page: page})}
                />}
            </div>
        </section>
    );
}


function NavigationItem({children, onClick, isSelected, route}) {
    const baseClasses = `lg:px-5 px-4 py-2 cursor-pointer border-b-4  whitespace-nowrap`
    return (
        <>
            <NavLink
                to={route}
                className={({isActive}) => (isActive ? `border-green-500 ${baseClasses}` : `border-transparent ${baseClasses}`)}
            >
                {children}
            </NavLink>
        </>
    );
}

function NavigationBar() {
    const navigationItems = [
        {id: 1, text: "Current Subscribers", route: "/SUP01"},
        {id: 2, text: "Update Public Solution Page", route: "/SUP01_2"},
        {id: 3, text: "Update Subscriber Support Center", route: "/SUP01_3"},
        {id: 4, text: "Public Home Pages", route: "/SUP01_4"},
        {id: 5, text: "Subscribe", route: "/Sup1_5_2"},
    ];


    return (
        <nav
            className="lg:mt-14 mt-8 flex overflow-x-auto no-scrollbar gap-2 lg:gap-5 px-4 py-2 justify-start items-center lg:text-xl text-lg font-semibold text-zinc-800 snap-x md:justify-center md:snap-none md:flex-wrap">
            {navigationItems.map((item) => (
                <NavigationItem
                    key={item.id}
                    route={item.route}
                >
                    {item.text}
                </NavigationItem>
            ))}
        </nav>
    );
}


const SUP01 = () => {
    const dispatch = useDispatch()


    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', ''], 'Current Subscribers']}/>
            <NavigationBar/>
            <PostsPage/>
            <Footer hideCopyright={true}/>
        </>
    );
};

export default SUP01;
