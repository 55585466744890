import {format} from "date-fns";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import api from "../../api";
import {getAllComunicationsFetch, sendMessageToApplicantFetch} from "../../redux/thunks/communicationsThunks";
import Button from "../base/Button";
import InputFieldHorisontal from '../base/InputFieldHorisontal';
import ModalTemplate from '../base/ModalTemplate';
import PrimaryButton from "../base/PrimaryButton";
import SelectBox from "../base/SelectBox";
import TextareaFieldHorisontal from '../base/TextareaFieldHorisontal';
import CommunicationTable from "../../pages/SBM01/parts/CommunicationTable";
import PageLoader from "../PageLoader";
import {getSubscriberRole} from "../../redux/slices/applicationSlice";

const EmailModal = ({
                        open,
                        handleClose,
                        tableHeding = false,
                        tableHedingButton = false,
                        footerBorderTop = true,
                        data,
                        selectedApplicant = null
                    }) => {
    const dispatch = useDispatch()
    const {jobId} = useParams();
    const {isLoading: isCommunicationLoading} = useSelector(state => state.communications);
    const applicationId = selectedApplicant?.id
    const subscriberRole = useSelector(getSubscriberRole);

    const [jobData, setJobData] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [applicationData, setApplicationData] = useState(null);

    const getJobApplicationById = async () => {
        setIsLoading(true)
        const jobRes = await api.jobs.getJobsById(jobId);
        setJobData(jobRes?.data);
        const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
        setApplicationData(res?.data);
        setFormData(prev => ({
            ...prev,
            fullName: res?.data?.fullName,
            phone: res?.data?.phone,
            email: res?.data?.email,
        }))
        setIsLoading(false)
    }

    useEffect(() => {
        getJobApplicationById();
    }, [])

    useEffect(() => {
        if (!applicationId) return
        dispatch(getAllComunicationsFetch({applicationId}))
    }, [applicationId])


    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        content: '',
    });
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    };

    const handleSumbit = async () => {
        if (!formData.content) return
        const res = await dispatch(sendMessageToApplicantFetch({
            applicationId,
            body: {
                type: "email",
                content: formData.content
            }
        }))
        if (res) {
            setFormData(prev => ({...prev, content: ""}))
        }
    }

    return (
        <ModalTemplate open={open} onClick={handleClose} modalSize="max-w-[772px]">
            <div className="modal__header">
                <h2 className="text-[16px] md:text-[20px] font-bold">Email</h2>
                <button className="btn btn-close" onClick={handleClose}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L24 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M24 12L12 24" stroke="#B7B7B7" strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            {
                !isLoading ?
                    <>
                        <div className="modal__body">
                            <div className="w-full">
                                <div className="flex flex-col gap-[16px] mb-[32px]">
                                    <InputFieldHorisontal
                                        disabled={true}
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Name"
                                        labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="name" id="name"
                                        value={applicationData?.fullName}
                                        onChange={handleChange}
                                        placeholder="John Doe"
                                    />
                                    <InputFieldHorisontal
                                        disabled={true}
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Phone Number"
                                        labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="phone" id="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="123-453-1232"
                                    />
                                    <InputFieldHorisontal
                                        disabled={true}
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Email"
                                        labelClassName="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="email" id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="123-453-1232"
                                    />
                                    <TextareaFieldHorisontal
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        label="Content"
                                        labelClassName="flex items-center min-w-[140px] text-[16px] md:min-h-[46px] font-semibold text-zinc-800 leading-[18px]"
                                        type="text" name="content" id="content"
                                        value={formData.content}
                                        onChange={handleChange}
                                        rows={4}
                                    />
                                    <div
                                        className="flex max-md:flex-col md:items-center gap-[12px] md:gap-[16px] flex-1">
                                        <label
                                            className="min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]">Template</label>
                                        <SelectBox options={
                                            [
                                                {
                                                    label: 'Template 1'
                                                },
                                                {
                                                    label: 'Template 2'
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                                {tableHeding && <div className="flex items-center justify-between gap-2">
                                    <div className="text-[18px] md:text-[20px] font-semibold leading-8 text-zinc-800">
                                        Communication History
                                    </div>
                                    {tableHedingButton && <Button
                                        className={'text-white bg-green-500 md:min-w-[122px] min-h-[52px] text-[18px]'}> Dial </Button>}
                                </div>}
                                <div className="flex flex-col mt-[16px] gap-[32px]">
                                    {data.map(dataItem =>
                                        <div className="flex flex-col self-stretch" key={dataItem.name}>
                                            <h2 className="w-full text-[18px] font-semibold text-stone-500 max-md:max-w-full mb-[10px]">{dataItem.name}</h2>
                                            <CommunicationTable isSorted={false} type={"email"}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {subscriberRole !== "interviewer" &&
                              <div className={`modal__footer ${footerBorderTop ? 'modal__footer-border' : ''}`}>
                                  <Button
                                      className={'text-gray-400 max-md:w-full min-w-[122px] min-h-[52px] text-[18px]'}
                                      onClick={handleClose}>
                                      <span className="underline">Cancel</span>
                                  </Button>
                                  <PrimaryButton className={'w-[122px] !h-[52px] !text-[18px]'}
                                                 onClick={handleSumbit} isLoading={isCommunicationLoading}>
                                      Submit
                                  </PrimaryButton>
                              </div>
                        }
                    </>
                    :
                    <PageLoader/>

            }
        </ModalTemplate>
    )
};

export default EmailModal
