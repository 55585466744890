import React, { useRef, useState } from "react";
import api from '../api';
import Breadcrumbs from "../components/base/Breadcrumbs";
import PrimaryButton from "../components/base/PrimaryButton";
import PrimaryLink from "../components/base/PrimaryLink";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentPage, setToken, } from "../redux/slices/applicationSlice.js";
import { getUserFetch } from "../redux/thunks/applicationThunks.js";

import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import findValue from "../assets/helpers/findValue.js";
import PageLoader from "../components/PageLoader.jsx";

import { ReactComponent as SpinnerIcon } from "../assets/img/spinner.svg";
import InputField from "../components/base/InputField";


const SignInButton = ({ src, alt, children }) => (
	<a href={`${process.env.REACT_APP_API_URL_CLEAR}/oauth/google`} className="flex justify-center items-center px-16 lg:h-[64px] h-[60px] mt-8 max-w-full text-[19px] lg:text-[19px] lg:text-xl whitespace-nowrap bg-white rounded-md text-zinc-700 w-[562px] max-md:px-5 max-md:mr-2.5">
		<div className="flex gap-3">
			<img loading="lazy" src={src} alt={alt} className="w-8 aspect-square" />
			<div className="grow my-auto">{children}</div>
		</div>
	</a>
);

// const InputField = ({ placeholder, onInput, value = "", type = "text" }) => (
// 	<input
// 		type={type}
// 		placeholder={placeholder}
// 		value={value}
// 		onInput={onInput}
// 		className="justify-center items-start h-[52px] lg:h-16 pr-16 pl-6 mt-5 max-w-full text-[19px] lg:text-xl whitespace-nowrap bg-white rounded-md text-zinc-500 w-[562px] max-md:px-5 max-md:mr-2.5"
// 	/>
// );


function LoginPage() {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);


	const dispatch = useDispatch();
	const navigate = useNavigate();
	const recaptchaRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [isLoginFailed, setIsLoginFailed] = useState(false)
	const [isRecaptchaError, setIsRecaptchaError] = useState(false)
	const [loginErrorMessage, setLoginErrorMessage] = useState(false)
	const [twoFactorAuthRequired, setTwoFactorAuthRequired] = useState(false)
	const hanldeCapchaChange = (value) => {
		setIsRecaptchaError(!value)
	}
	const [payload, setPayload] = React.useState({
		email: "",
		password: "",
		// twoFactorCode: "95-A7-9F-C7-47-80"
	});

	const login = async () => {
		const recaptchaValue = recaptchaRef.current.getValue();

		if (!recaptchaValue) {
			setIsRecaptchaError(true)
			return
		}
		setIsLoading(true)

		const response = await api.auth.login({ ...payload, capchaKey: recaptchaValue });
		if (response.success && response.twoFactorAuth && !payload.twoFactorCode) {
			toast.success(response.message || "Authorization code sent to email")
			setTwoFactorAuthRequired(true)
			setIsLoading(false)
			return;
		}
		if (response?.success && response.data.token) {
			localStorage.setItem('authToken', response.data.token);
			dispatch(setToken(response.data.token));
			let userAfterLoginData = await dispatch(getUserFetch());
			if (["subscriber", "team-member"].includes(userAfterLoginData?.payload?.role)) {
				navigate('/SBM01');
			} else if (userAfterLoginData?.payload?.role === "support") {
				navigate('/SUP01');
			} else {
				navigate('/HOM1_1');
			}
			toast.success("Login success")
		} else {
			setIsLoginFailed(true)
			setLoginErrorMessage(response?.message || "Something went wrong")
			toast.error(response?.message || "Something went wrong")
		}
		setIsLoading(false)
	}
	const breadcrumbItems = [
		{ name: 'Home', to: "/HOM1_1", isCurrent: false },
		{ name: 'Log In', to: "/HOM_1_6", isCurrent: true },
	];
	return (<>
		<>
			<Breadcrumbs links={breadcrumbItems} />
			{!currentPage ?
			<PageLoader color="light" /> :
				<div className="flex relative flex-col text-center text-balance items-center mt-2.5 mb-[90px] w-full max-w-[677px] px-4 mx-auto">
					<h2 className="mt-8 lg:text-[70px] text-wrap text-white lg:leading-[79.8px] font-bold max-md:max-w-full leading-[48px] text-[40px]">
						{getValue('login_title')}
					</h2>
					<div className="self-center mt-6 h-1 bg-[#37B34A] w-[78px]" />
					<div className="flex flex-col justify-center items-center max-w-[560px] w-full mx-auto">
						<SignInButton src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e5f03387e510f5b32af280b63ab0ba9deaece36abad31da95dda6b1c9f54e70?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&" alt="Sign in with Google icon">Sign in with Google</SignInButton>
						<div className="self-center mt-5 text-[19px] lg:text-xl font-light text-white">or</div>
						<InputField
							parentClassName={"w-full"}
							className={"items-start h-[52px] lg:h-16 pr-16 pl-6 mt-5 text-[19px] lg:text-xl whitespace-nowrap !bg-white rounded-md text-zinc-500 w-full max-md:px-5 max-md:mr-2.5"}
							placeholder="Email or Username"
							value={payload.email}
							onInput={(e) => {
								setPayload((prev) => {
									return {
										...prev,
										email: e.target.value
									}
								})
							}}
						/>
						<InputField
							parentClassName={"w-full"}
							className={"items-start h-[52px] lg:h-16 pr-16 pl-6 text-[19px] lg:text-xl whitespace-nowrap !bg-white rounded-md text-zinc-500 w-full max-md:px-5 max-md:mr-2.5"}
							type="password"
							placeholder="Password"
							value={payload.password}
							onInput={(e) => {
								setPayload((prev) => {
									return {
										...prev,
										password: e.target.value
									}
								})
							}}
						/>
						{
							twoFactorAuthRequired ?
								<InputField
									parentClassName={"w-full"}
									className={"items-start h-[52px] lg:h-16 pr-16 pl-6 text-[19px] lg:text-xl whitespace-nowrap !bg-white rounded-md text-zinc-500 w-full max-md:px-5 max-md:mr-2.5"}
									type="text"
									placeholder="Verification code"
									value={payload?.twoFactorCode}
									onInput={(e) => {
										setPayload((prev) => {
											return {
												...prev,
												twoFactorCode: e.target.value
											}
										})
									}}
								/>
								: null
						}
						<div className="mt-6">
							<ReCAPTCHA
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_SITE_KEY}
								onChange={hanldeCapchaChange}
								onErrored={(mess) => console.log(mess)}
								hl="en"
								className="w-full"
							/>
							{isRecaptchaError && <p className="text-red-500 text-sm mt-1">Invalid capcha</p>}
						</div>
						<PrimaryButton
							className='w-full mt-6'
							onClick={(e) => {
								e.preventDefault();
								login();
							}}
							disabled={isLoading}
						>
							{
								!isLoading ?
									getValue('login_button_complete')
									:
									<SpinnerIcon />
							}
						</PrimaryButton>
						{
							isLoginFailed && loginErrorMessage ?
								<p className="mt-11 md:text-[19px] lg:text-xl text-lg text-balance text-[red] font-normal w-full text-center">
									{loginErrorMessage}
								</p>
								:
								null
						}
						{/* {
							isLoginFailed  ?
								<p className="mt-11 md:text-[19px] lg:text-xl text-lg text-balance text-white font-normal w-full text-center">
									<PrimaryLink to={"/HOM1_6_1"}>Forgot your password?</PrimaryLink>
								</p>
								:
								null
						} */}
						<p className="mt-11 md:text-[19px] lg:text-xl text-lg text-balance text-white font-normal w-full text-center">
							<PrimaryLink to={"/HOM-1_6_1"}>Forgot your password?</PrimaryLink>
						</p>
					</div>
				</div>}
		</>
	</>
	);
}

export default LoginPage;
