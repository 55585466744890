import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import api from "../../../api";
import PageLoader from '../../../components/PageLoader';
import Checkbox from '../../../components/base/Checkbox';
import InputField from '../../../components/base/InputField';
import PrimaryButton from '../../../components/base/PrimaryButton';
import SelectBox from '../../../components/base/SelectBox';
import { getApplicationByIdFetch, getJobByIdFetch } from '../../../redux/thunks/jobsThunks';
import { ReportCardLayout } from '../layouts/ReportCardLayout';
import { getUser } from '../../../redux/slices/applicationSlice';
import userEvent from '@testing-library/user-event';
import Editor from '../../../components/CKEditor';
import CreateSignatureModal from '../../../components/modals/CreateSignatureModal';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';
import { employmentTypes, payFrequencies, payPeriods } from '../config';


const OfferMeeting = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch();

	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(11)
	}, [])
	const initPayload = {
		interviewType: "test",
		duration: "Week",
		date: "2024-06-14",
		times: ["09:30", "10:30", "11:20"],
		employmentType: "Full Time",
		startDate: "2024-06-14",
		payRate: 0,
		payPeriod: "Day",
		payFrequency: "Daily",
		recruterName: "Morty",
		offerLetter: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem actuantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugi t, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam , quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
		interviewerId: null
	};

	const errorFieldNames = {
		interviewType: "Interview Type",
		duration: "Duration",
		date: "Select Available Start Times",
		times: "Meeting Times",
		employmentType: "Employment Type",
		startDate: "Start Date",
		payRate: "Pay Rate",
		payPeriod: "Pay Period",
		payFrequency: "Pay Frequency",
		recruterName: "Recruiter Name",
		offerLetter: "Offer Letter",
		interviewerId: "Interviewer"
	};

	const [payload, setPayload] = useState(null);

	const saveOffer = async () => {
		let haveError = false;
		Object.keys(errorFieldNames).forEach((fieldName) => {
			if (fieldName === "payRate") {
				if (+payload[fieldName] === 0) {
					haveError = true;
					toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if (fieldName === "interviewerId") {
				if (payload[fieldName] === null) {
					// haveError = true;
					// toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if (!payload[fieldName]?.length) {
				haveError = true;
				toast.error(`${errorFieldNames[fieldName]} is required`);
			}
		});

		if (haveError) {
			console.log('payload:', payload);
			return false;
		}

		let res = await api.jobApplications.createOfferMeetings(applicationId, payload);

		if (res?.success) {
			toast.success(res?.message || 'Successfully')
		} else {
			toast.error(res?.message || 'Something went wrong')
		}
	}
	const [offerMode, setOfferMode] = useState('edit')
	const [initialHtml, setInitialHtml] = useState(null)
	const getOffer = async () => {
		let res = await api.jobApplications.getOfferMeetings(applicationId);
		if (res?.data) {
			setInitialHtml(res.data.offerLetter)
			if(res.data.signApplicant && !res.data.signCompany) {
				const {employmentType,
					payRate,
					payPeriod,
					payFrequency,
					startDate,
					recruterName,
					candidateName,
					signApplicant,
					companySign,
					offerLetter
				} = res.data
				const variables = { employmentType, payRate, payPeriod, payFrequency, startDate, recruterName, candidateName };
				let resultStr = offerLetter;
				for (const key in variables) {
					const regex = new RegExp(`{{${key}}}`, 'g'); // Create a regex to match the placeholder
					resultStr = resultStr.replace(regex, variables[key] || key);  // Replace all occurrences
				}
				console.log(resultStr);
				if (signApplicant) {
					const tempDiv = document.createElement('div');
					tempDiv.innerHTML = resultStr;
					const signatureDiv = tempDiv.querySelector('#candidate-sign');
					const image = new Image();
					// image.src = 'https://ej.webstaginghub.com' + signApplicant;
					image.src = process.env.REACT_APP_API_URL_CLEAR + signApplicant;
					image.id = 'candidate-sign'
					image.style = "width: 206px; height: 70px; object-fit: contain"
					signatureDiv?.parentNode?.replaceChild(image, signatureDiv);
					resultStr = tempDiv.innerHTML
				}
				setOfferMode('view-candidate')
				setPayload({...res.data, offerLetter: resultStr})
			} else if(res.data.signApplicant && res.data.signCompany && res.data.pdf) {
				setOfferMode('view-pdf');
				setPayload(res.data);
			}
			else {
				setPayload(res.data);
			}
		} else {
			setPayload(initPayload);
		}
	}

	useEffect(() => {
		if (!jobId || !applicationId) return;
		// dispatch(getJobByIdFetch(jobId))
		// dispatch(getApplicationByIdFetch({
		// 	applicationId,
		// 	jobId
		// }));

		getOffer();
	}, [jobId, applicationId, dispatch])


	const handlePrev = () => {
		navigate(`/SBM01_10_5_5/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_6/${jobId}/${applicationId}`)
	}
	const user = useSelector(getUser)
	const companyId = user?.company?.id || user?.companies?.[0]?.company?.id
	const [interviewers, setInterviewers] = useState([]);
	useEffect(() => {
		if (!companyId) return;
		api.companyTeam.getTeamMembers(companyId).then(response => {
			if (!response.success || !response?.data?.length) {
				return;
			}

			console.log("response", response)

			setInterviewers(response.data.map(member => ({ value: `${member?.user?.firstName} ${member?.user?.lastName}`, label: `${member?.user?.firstName} ${member?.user?.lastName}`, id: member.userId })))
		})
	}, [companyId])
	const handleEdit = () => {
		setOfferMode('edit');
		setPayload(prev => ({...prev, offerLetter: initialHtml}))
	}
	return (
		<>

					{payload && application ?
						<div className='flex-auto'>
							<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
								<div className='flex flex-col gap-11 lg:gap-[60px]'>
									<InterviewSetup
										payload={payload}
										setPayload={setPayload}
										application={application}
										interviewers={interviewers}
									/>
									<OfferLetterPage
										offerMode={offerMode}
										interviewers={interviewers}
										payload={payload}
										setPayload={setPayload}
										send={() => {
											saveOffer();
										}}
										handleEdit={() => {
											handleEdit();
										}}
										getOffer={getOffer}
									/>
								</div>
							</div>
							<NextStepSectionSBM01 next={handleNext} prev={handlePrev} />
						</div>
						:
						null}
			</>


	)
}

const InterviewSetup = ({ payload, setPayload, application, interviewers }) => {
	const [outsideBusinessHours, setOutsideBusinessHours] = useState(false);
	const timeSlots = outsideBusinessHours ? [
		"12:00 am", "12:30 am", "01:00 am",
		"01:30 am", "02:00 am", "02:30 am",
		"03:00 am", "03:30 am", "04:00 am",
		"04:30 am", "05:00 am", "05:30 am",
		"06:00 am", "06:30 am", "07:00 am",
		"07:30 am", "08:00 am", "08:30 am",
		"09:00 am", "09:30 am", "10:00 am",
		"10:30 am", "11:00 am", "11:30 am",
		"12:00 pm", "12:30 pm", "01:00 pm",
		"01:30 pm", "02:00 pm", "02:30 pm",
		"03:00 pm", "03:30 pm", "04:00 pm",
		"04:30 pm", "05:00 pm", "05:30 pm",
		"06:00 pm", "06:30 pm", "07:00 pm",
		"07:30 pm", "08:00 pm", "08:30 pm",
		"09:00 pm", "09:30 pm", "10:00 pm",
		"10:30 pm", "11:00 pm", "11:30 pm",
	] : [
		"09:00 am", "09:30 am", "10:00 am",
		"10:30 am", "11:00 am", "11:30 am",
		"12:00 pm", "12:30 pm", "01:00 pm",
		"01:30 pm", "02:00 pm", "02:30 pm",
	];

	const [localPayload, setLocalPayload] = useState({ ...payload });

	const [confirmed, setConfirmed] = useState(true);

	const timeFrom12To24Format = (time) => {
		var hours = Number(time.match(/^(\d+)/)[1]);
		var minutes = Number(time.match(/:(\d+)/)[1]);
		var AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM == "pm" && hours < 12) hours = hours + 12;
		if (AMPM == "am" && hours == 12) hours = hours - 12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if (hours < 10) sHours = "0" + sHours;
		if (minutes < 10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}

	useEffect(() => {
		setConfirmed(false);
	}, [localPayload]);

	return (
		<main className="flex flex-col py-9 px-8 lg:p-14 rounded-md border border-solid border-zinc-300">
			<section className="max-md:max-w-full">
				<header className="flex gap-5 max-md:flex-col max-md:gap-0">
					<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
						<section className="flex flex-col justify-center max-md:max-w-full">
							<h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">Finalize the interview details</h2>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interview Type</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={[
									{ label: 'test', value: 'test' },
									{ label: 'test 2', value: 'test 2' }
								]}
								svg={2}
								value={localPayload.interviewType}
								onSelect={(value) => {
									setLocalPayload((prev) => ({
										...prev,
										interviewType: value
									}))
								}}
							/>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Duration</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={[
									{ label: 'Day', value: 'Day' },
									{ label: 'Week', value: 'Week' },
									{ label: 'Month', value: 'Month' },
								]}
								svg={2}
								value={localPayload.duration}
								onSelect={(value) => {
									setLocalPayload((prev) => ({
										...prev,
										duration: value
									}))
								}}
							/>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interviewer</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={interviewers}
								svg={2}
								value={localPayload.interviewerId}
								onSelect={(value) => {
									setLocalPayload((prev) => ({
										...prev,
										interviewerId:  interviewers.find(one => one.value = value)?.id
									}))
								}}
							/>
						</section>
					</div>
					<div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
						<section className="flex flex-col grow justify-center text-lg text-stone-500 max-md:mt-10 max-md:max-w-full">
							<h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">
								<span className='font-normal'>Calendar for:</span> {application?.fullName}
							</h2>
							<h3 className="mt-8 mb-[16px] font-semibold text-zinc-800 max-md:max-w-full">Select available start times</h3>
							<InputField
								type='date' inputBg='bg-white border border-solid border-[#DEDED9]'
								value={localPayload.date}
								onChange={(e) => {
									setLocalPayload((prev) => ({
										...prev,
										date: e.target.value
									}))
								}}
							/>
							<div className="flex gap-4 items-center self-start p-1 mt-[14px] whitespace-nowrap">
								<div className="shrink-0 w-3 h-3 bg-green-500 rounded-[360px]"></div>
								<div className="grow">30 Minute Meeting</div>
							</div>
							<div className="flex gap-[22px] lg:gap-5 justify-between mt-[22px] lg:mt-6 w-full max-md:flex-wrap max-md:max-w-full">
								<div className="flex gap-3 whitespace-nowrap">
									<Checkbox
										checked={outsideBusinessHours}
										onChange={(value) => {
											setOutsideBusinessHours(value);
										}}
									/>
									<div className="grow my-auto">Show outside ‘business hours’</div>
								</div>
								<div className="flex gap-3">
									<Checkbox
										checked={timeSlots.length === localPayload.times?.length}
										onChange={(value) => {
											setLocalPayload((prev) => ({
												...prev,
												times: value ? timeSlots.map(time => timeFrom12To24Format(time)) : []
											}));
										}}
									/>
									<div className="my-auto">Select All</div>
								</div>
							</div>
							<div className="grid grid-cols-2 lg:grid-cols-4 gap-[13px] mt-8 whitespace-nowrap text-neutral-400 max-md:flex-wrap">
								{timeSlots.map((time, index) => (
									<a
										href="#"
										className={`h-[54px] flex items-center justify-center bg-white border border-solid border-zinc-300 max-md:px-5 ${localPayload?.times.includes(timeFrom12To24Format(time)) ? '!bg-[#37B34A] !text-[#fff]' : ''}`}
										key={index} time={time}
										onClick={(e) => {
											e.preventDefault();
											setLocalPayload((prev) => ({
												...prev,
												times: prev.times.includes(timeFrom12To24Format(time)) ? [...prev.times].filter(item => item !== timeFrom12To24Format(time)) : [...prev.times, timeFrom12To24Format(time)]
											}))
										}}
									>
										{time}
									</a>
								))}
							</div>
						</section>
					</div>
				</header>
			</section>
			<footer className="flex flex-col items-end pt-8 lg:pl-16 mt-8 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-zinc-300 w-full">
				<PrimaryButton
					className={`w-full lg:w-[120px] !h-[52px] !text-lg ${confirmed ? 'disabled' : ''}`}
					onClick={(e) => {
						e.preventDefault();
						setPayload(localPayload);
						setConfirmed(true);
					}}
				>Confirm</PrimaryButton>
			</footer>
		</main>
	);
};


function OfferLetterPage({ payload, setPayload, send, interviewers, offerMode, handleEdit, getOffer }) {
	const user = useSelector(getUser)
	const [templates, setTemplates] = useState([]);
	const [selectedTemplateId, setSelectedTemplateId] = useState(null)
	console.log(templates)
	useEffect(() => {
		console.log(user)
		const companyId = user?.company?.id || user?.companies?.[0]?.company?.id
		if(!companyId) return
		api.offerTemplates.getAll(companyId).then(response => {
			if(!response.success) {
				return;
			}
			setTemplates(response.data)
		})
	}, [user])
	const { jobId, applicationId } = useParams();
	const [emailLoading, setEmailLoading] = useState(false);
	const handleEmailLetter = async() => {
		if (!payload) {
			toast.error("Please save the offer letter first.")
		}
		setEmailLoading(true)
		const response = await api.jobApplications.sendOfferEmail(applicationId);
		setEmailLoading(false);

		if (response?.message?.includes("meeting not found")) {
			toast.error(response.message + ". Please save the offer letter first.")
			return
		}

		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
		console.log(response)
	}
	const [rteInstanse, setRteInstanse] = useState(null);
	console.log(rteInstanse)
	const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);
	const [base64Image, setBase64Image] = useState(null);
	const insertImage = (blockId, imageUrl) => {
        var iframe = rteInstanse.iframe
        var iframeContent = iframe.contentDocument || iframe.contentWindow.document;
        var paragraph = iframeContent.getElementById(blockId);

        if (paragraph) {
            console.log(paragraph);
            rteInstanse.selectControl(paragraph)
			// var img= rteInstanse.insertByTagName("IMG");
			// img.src=imageUrl;
			// rteInstanse.insertHTML(`<img id='${blockQuery}' src='${imageUrl}'></img>`);
            rteInstanse.insertImageByUrl(imageUrl);
			var img = iframeContent.querySelector(`img[src="${imageUrl}"]`);
			console.log(img)
			img.id = blockId
			img.style = "width: 206px; height: 70px; object-fit: contain"
        }

    };
	const handleSign = async () => {

		if (!base64Image) {
			toast.error("Sign first")
			return
		}

		const formdata = new FormData();
		const blobFile = setSignatureFile(base64Image);
		formdata.append("signature", blobFile);

		const res = await api.jobApplications.signSignatureCompany(applicationId, formdata)

		if (res?.message) {
			if (res?.message?.includes("meeting not found")) {
				toast.error(res.message + " send offer first")
				return
			}

			toast.error(res.message)
		}

		getOffer()
	}
	const setSignatureFile = (imgBase64) => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], { type: mimeString })
        }

        const file = DataURIToBlob(imgBase64);

        return file;
    }
	return <div>
		<div className="flex gap-12 max-md:flex-col max-md:gap-0">
			{offerMode !== 'view-pdf' &&
				<aside className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
					<div className="flex flex-col px-8 lg:py-14 py-9 text-lg rounded-md border border-solid border-zinc-300">
						<h2 className="text-xl font-bold text-zinc-800">Select Template</h2>
						<SelectBox
							options={templates.map(item => ({label: item.label, value: item.id}))}
							className="mt-4 !h-[54px]"
							value={selectedTemplateId}
							onSelect={(value) => {
								setSelectedTemplateId(value);
								const template  = templates.find(({id}) => id === value);
								console.log(template.content);
								setPayload((prev) => ({
									...prev,
									employmentType: template.employmentType,
									payRate: template.payRate,
									payPediod: template.payPeriod,
									payFrequency: template.payFrequency,
									recruterName: template.recruterName,
									offerLetter: template.content
								}))
							}}
							placeholder='Select Offer Template...'
						/>
						<h2 className="mt-8 text-xl font-bold text-zinc-800">Employment Type {payload.employmentType}</h2>
						<SelectBox
							options={employmentTypes}
							className="mt-4 !h-[54px]"
							value={payload.employmentType}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									employmentType: value
								}))
							}}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

						<h2 className="mt-8 text-xl font-bold text-zinc-800">Start Date</h2>
						<InputField
							type='date' inputBg='bg-white border border-solid border-[#DEDED9] mt-3.5'
							value={payload.startDate}
							onChange={(e) => {
								setPayload((prev) => ({
									...prev,
									startDate: e.target.value
								}))
							}}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

						<h2 className="mt-8 text-xl font-bold text-zinc-800">Compensation</h2>
						<div className="flex gap-3.5 justify-center mt-8">
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Rate</div>
								<InputField
									inputBg='bg-white border border-solid border-[#DEDED9]'
									value={payload.payRate}
									onInput={(e) => {
										setPayload((prev) => ({
											...prev,
											payRate: e.target.value
										}))
									}}
								/>
							</div>
							<div className="mb-[14px] flex items-center lg:mb-[18px] h-[54px] mt-auto text-neutral-500">per</div>
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Period</div>
								<SelectBox
									options={payPeriods}
									className="mb-[18px] !h-[54px]"
									value={payload.payPeriod}
									onSelect={(value) => {
										setPayload((prev) => ({
											...prev,
											payPeriod: value
										}))
									}}
								/>
							</div>
						</div>

						<div className="text-neutral-500">Pay Frequency</div>
						<SelectBox
							options={payFrequencies}
							className="mt-3.5 !h-[54px]"
							value={payload.payFrequency}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									payFrequency: value
								}))
							}}
						/>


						<div className="mt-4 lg:mt-6 text-neutral-500">Recruiter Name</div>
						<SelectBox
							options={interviewers}
							className="mt-3.5 !h-[54px]"
							value={payload.recruterName}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									recruterName: value
								}))
							}}
							placeholder={'Select recruiter...'}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />
						{(offerMode === 'edit' && !base64Image) && <button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={(e) => {
								e.preventDefault();
								send();
							}}
						>
							Save Offer Letter
						</button>}
						{(offerMode === 'view-candidate' || base64Image) && <button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={(e) => {
								e.preventDefault();
								handleEdit();
								setBase64Image(null)
							}}
						>
							Edit Offer Letter
						</button>}
						<button
							onClick={handleEmailLetter}
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-3.5 font-bold text-center text-white whitespace-nowrap bg-sky-900 rounded-md max-md:px-5"
						>
							{emailLoading ? <PageLoader color='light' className='h-[40px]'/> : 'Email Offer Letter'}

						</button>
					</div>
				</aside>
			}
			<main className="flex flex-col lg:w-[70%] w-full max-lg:mt-11 mx-auto">
				<div className="flex flex-col grow self-stretch w-full">
					<h1 className="text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Offer Letter</h1>
					{offerMode === 'edit' &&
						<Editor
						wrprClassNames="my-4"
						data={payload.offerLetter}
						onChange={val => setPayload((prev) => ({
							...prev,
							offerLetter: val
						}))}
						setInstanse={rte => setRteInstanse(rte)}
						readOnly={false}
					/>}
					{offerMode === 'view-candidate' &&
						<Editor
						wrprClassNames="my-4"
						data={payload.offerLetter}
						onChange={val => setPayload((prev) => ({
							...prev,
							offerLetter: val
						}))}
						setInstanse={rte => setRteInstanse(rte)}
						readOnly={true}
					/>}
					{offerMode === 'view-pdf' &&
						<iframe
							src={process.env.REACT_APP_API_URL + payload.pdf}
							width="100%"  className="mt-[36px] h-[calc(100vh-100px)]"
						/>
					}
					{offerMode !== 'view-pdf' &&
					<div className="flex items-center justify-center gap-5">
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={(e) => {
								e.preventDefault();
								setCreateSignatureModalOpen(true)
							}}
						>
							Sign
						</button>
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={handleSign}
						>
							Complete and Continue
						</button>
					</div>
					}

					{createSignatureModalOpen ? <CreateSignatureModal
						open={createSignatureModalOpen}
						handleClose={()=>{ setCreateSignatureModalOpen(false); }}
						setSignature={(base64Image)=>{
							setBase64Image(base64Image)
							insertImage('company-sign', base64Image)
						}}
					/> : null }
				</div>
			</main>
		</div>

	</div>;
}

export default OfferMeeting
