import React, {useEffect, useState} from "react";
import {EditableInput} from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import {ButtonOptions} from "./ButtonOptions";
import {ModalForm} from "./ModalForm";
import {getTotallyLabel} from "../AP_01_Form/parts/RangeQuestion";


const FitScaleEvaluationTable = ({
                                     value = 1,
                                     onChange,
                                     maxOptions = 6,
                                     labels = ["Totally Disagree", "Totally Agree"]
                                 }) => {
    const createRatingScale = (selectedIdx) => Array.from({length: maxOptions}).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col w-full  max-w-[600px]">
            <div
                className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={() => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4  border border-solid ${isSelected ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">{labels[0]}</div>
                <div className="flex-auto text-right">{labels[1]}</div>
            </div>
        </div>
    );
}

export const CriticalRequirementsSection = ({payload, setPayload, questions}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isQuestionEditing, setIsQuestionEditing] = useState();


    const [isModal, setIsModal] = useState(false);

    const [requirements, setRequirements] = useState(questions);

    // const [requirements, setRequirements] = useState(payload?.applicationForm?.critical_requirements_of_the_job_questions?.length ? [...payload?.applicationForm?.critical_requirements_of_the_job_questions] : []);
    // console.log('requirements:', requirements);

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
        console.log(formData);
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.critical_requirements_of_the_job_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        critical_requirements_of_the_job_active: !payload.applicationForm.critical_requirements_of_the_job_active
                    }
                });
            }
        },
    ]

    const [formData, setFormData] = useState({
        title: "Critical Requirements of the Job",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis"
    });

    const requirementsHandleChange = (e, index) => {
        const {name, value} = e.target;
        let newItem = [...requirements][index];
        newItem[name] = value;
        setRequirements(prevState => [...prevState].map((item, index2) => {
            return index2 === index ? newItem : item
        }));
    }
    const requirementSpecialChange = (e, id) => {
        const {name, value} = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                edited_critical_requirements: {...payload?.applicationForm?.edited_critical_requirements, [id]: value}
            }
        })
    }

    // useEffect(() => {
    //     setPayload({
    //         ...payload,
    //         applicationForm: {
    //             ...payload.applicationForm,
    //             critical_requirements_of_the_job_questions: requirements
    //         }
    //     });
    // }, [requirements]);


    useEffect(() => {
        //need remove
        const editedQuestions = questions.map((question) =>
            question.id === 28 || question.id === 22 || question.id === 23
                ? {...question, answerType: "yes-no-text"}
                : question
        );
        //need remove

        setRequirements(editedQuestions);
    }, [questions]);


    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='w-full max-w-[734px]'>
                    <div className='max-w-[650px] w-full'>
                        <EditableInput
                            className="lg:text-[32px] text-[24px] font-bold"
                            type="text"
                            name="critical_requirements_of_the_job_title"
                            id="critical_requirements_of_the_job_title"
                            value={payload.applicationForm.critical_requirements_of_the_job_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <EditableInput
                            className="text-lg text-[#6D6D6D] leading-[30px] w-full"
                            type="text"
                            name="critical_requirements_of_the_job_text"
                            id="critical_requirements_of_the_job_text"
                            value={payload.applicationForm.critical_requirements_of_the_job_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div className='flex flex-col gap-9 py-8'>
                        {requirements.map((requirement, index) => {
                            return <div key={index}>
                                <EditableInput
                                    className="lg:text-[22px] lg:font-bold text-[18px] font-semibold"
                                    type="text"
                                    name="question"
                                    id="question"
                                    value={index + 1 + ". " + requirement.question}
                                    onChange={(e) => {
                                        requirementsHandleChange(e, index)
                                    }}
                                    disabled={!isEditing}
                                />
                                {
                                    requirement.answerType === "range" ?
                                        <FitScaleEvaluationTable
                                            value={JSON.parse(requirement.answerTemplate).min}
                                            maxOptions={JSON.parse(requirement.answerTemplate).max}
                                            onChange={() => {
                                            }}
                                            labels={getTotallyLabel(requirement.id)}
                                        /> : requirement.answerType === "yes-no" ?
                                            <div className="flex items-center justify-between gap-[16px]">
                                                {
                                                    [
                                                        {value: "no", label: "No"},
                                                        {value: "yes", label: "Yes"},
                                                    ].map((option, index) => (
                                                        <label key={option.label}
                                                               className="cursor-pointer custopm-radio flex items-center font-medium">
                                                            <input
                                                                type="radio"
                                                                className="radio-green-sub aspect-square w-5 mr-[10px]"
                                                                name={requirement.question} hidden/>
                                                            {index === 0 &&
                                                                <span className="check-box-fake mr-[12px]"></span>}
                                                            <EditableInput
                                                                className={`text-[18px] max-w-[32px] ${!isEditing ? 'pointer-events-none' : ''}`}
                                                                type="text"
                                                                name="positive"
                                                                id="positive"
                                                                value={option.label}
                                                                onChange={handleChange}
                                                                disabled={!isEditing}
                                                            />
                                                            {index > 0 &&
                                                                <span className="check-box-fake ml-[12px]"></span>}
                                                        </label>
                                                    ))
                                                }
                                            </div> :
                                            <div className="max-w-[600px] w-full">
                                                <div className="w-full">
                                                    <input className="grey-input-custom"/>
                                                </div>
                                            </div>
                                }
                            </div>
                        })}
                    </div>

                </div>
            </ModalForm>
        )
    }


    console.log("payload.applicationForm", payload.applicationForm)

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-3">
                {
                    isEditing ?
                        <EditableInput
                            className="lg:text-[32px] text-[24px] font-bold"
                            type="text"
                            name="critical_requirements_of_the_job_title"
                            id="critical_requirements_of_the_job_title"
                            value={payload.applicationForm.critical_requirements_of_the_job_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        /> : <div className="lg:text-[32px] text-[24px] font-bold">
                            {payload.applicationForm.critical_requirements_of_the_job_title}
                        </div>}
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton> :
                        <ButtonOptions options={options}/>
                }
            </div>
            {
                payload.applicationForm.critical_requirements_of_the_job_active ?
                    <div className='w-full'>
                        <div className='w-full'>
                            <EditableInput
                                className="text-lg text-[#6D6D6D] leading-[30px] w-full"
                                type="text"
                                name="critical_requirements_of_the_job_text"
                                id="critical_requirements_of_the_job_text"
                                value={payload.applicationForm.critical_requirements_of_the_job_text}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                        </div>
                        <div className='flex flex-col gap-9 py-8'>
                            {/*{!payload?.applicationForm?.critical_requirements_of_the_job_questions?.length ?*/}
                            {/*    <h3 className="font-[700] text-[red]">Select Qualification Attributes Please</h3> : */}
                            {payload?.applicationForm?.critical_requirements_of_the_job_questions?.length &&
                                requirements.map((requirement, index) => {
                                    const isSpecialRequirement = [25, 30, 20, 21, 22].includes(requirement.id);
                                    // if (requirement.answerType === "text") {
                                    return <div key={index} className="relative">
                                        {!isSpecialRequirement ? (
                                            isEditing ? (
                                                <EditableInput
                                                    className="lg:text-[22px] lg:font-bold text-[18px] font-semibold"
                                                    type="text"
                                                    name="question"
                                                    id="question"
                                                    value={requirement.question}
                                                    onChange={(e) => requirementsHandleChange(e, index)}
                                                    disabled={!isEditing}
                                                />
                                            ) : (
                                                <div
                                                    className="lg:text-[22px] lg:font-bold text-[18px] font-semibold flex justify-between max-w-[600px] ">
                                                      <span>
                                                        {index + 1 + ". " + requirement.question}
                                                          <span className="text-[#37B34A]">*</span>
                                                      </span>
                                                </div>
                                            )
                                        ) : (
                                            <div className="relative">
                                                {!isQuestionEditing?.[requirement.id] ? (
                                                    <div
                                                        className="lg:text-[22px] lg:font-bold text-[18px] font-semibold flex justify-between  max-w-[600px]">
                                                        <span>
                                                          {index + 1 + ". " + (payload?.applicationForm?.edited_critical_requirements?.[requirement.id] || requirement.question)}
                                                            <span className="text-[#37B34A]">*</span>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <EditableInput
                                                        className="lg:text-[22px] lg:font-bold text-[18px] font-semibold  max-w-[600px]"
                                                        type="text"
                                                        name="question"
                                                        id="question"
                                                        value={payload.applicationForm?.edited_critical_requirements?.[requirement.id] || requirement.question}
                                                        onChange={(e) => requirementSpecialChange(e, requirement.id)}
                                                    />
                                                )}

                                                <div className="absolute right-0 top-0">
                                                    {isQuestionEditing?.[requirement.id] ? (
                                                        <PrimaryButton
                                                            type="submit"
                                                            className="px-9 lg:h-[52px] lg:text-lg"
                                                            onClick={() => setIsQuestionEditing((prev) => ({
                                                                ...prev,
                                                                [requirement.id]: false,
                                                            }))}
                                                        >
                                                            Save
                                                        </PrimaryButton>
                                                    ) : (
                                                        <ButtonOptions
                                                            options={[
                                                                {
                                                                    label: "Edit question...",
                                                                    handler: () =>
                                                                        setIsQuestionEditing((prev) => ({
                                                                            ...prev,
                                                                            [requirement.id]: true,
                                                                        })),
                                                                },
                                                            ]}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}


                                        {
                                            requirement.answerType === "range" ?
                                                <FitScaleEvaluationTable
                                                    value={JSON.parse(requirement.answerTemplate).min}
                                                    maxOptions={JSON.parse(requirement.answerTemplate).max}
                                                    onChange={() => {
                                                    }}
                                                    labels={getTotallyLabel(requirement?.id)}
                                                /> : requirement.answerType === "yes-no" ?
                                                    <div className="flex items-center justify-between gap-[16px] ">
                                                        {
                                                            [
                                                                {value: "no", label: "No"},
                                                                {value: "yes", label: "Yes"},
                                                            ].map((option, index) => (
                                                                <label key={option.label}
                                                                       className="cursor-pointer custopm-radio flex items-center font-medium">
                                                                    <input
                                                                        type="radio"
                                                                        className="radio-green-sub aspect-square w-5 mr-[10px]"
                                                                        name={requirement.question} hidden/>
                                                                    {index === 0 &&
                                                                        <span className="check-box-fake mr-[12px]"></span>}
                                                                    <EditableInput
                                                                        className={`text-[18px] max-w-[32px] ${!isEditing ? 'pointer-events-none' : ''}`}
                                                                        type="text"
                                                                        name="positive"
                                                                        id="positive"
                                                                        value={option.label}
                                                                        onChange={handleChange}
                                                                        disabled={!isEditing}
                                                                    />
                                                                    {index > 0 &&
                                                                        <span className="check-box-fake ml-[12px]"></span>}
                                                                </label>
                                                            ))
                                                        }
                                                    </div> : requirement.answerType === "variants" ?
                                                        <>
                                                            {/* answerType: { requirement.answerType }
                                            <br />
                                            answerTemplate: { requirement.answerTemplate }
                                            <br /> */}
                                                            {
                                                                JSON.parse(requirement.answerTemplate).type === "radio" ?
                                                                    <div className="flex flex-col w-full  max-w-[600px]">
                                                                        <div
                                                                            className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                                                                            {JSON.parse(requirement.answerTemplate).answers.map((answer, idx) => {
                                                                                return <div
                                                                                    key={idx}
                                                                                    className={`cursor-pointer`}
                                                                                    // onClick={() => handleRatingClick(idx + 1)}
                                                                                >
                                                                                    <div
                                                                                        key={idx}
                                                                                        className={`flex justify-center items-center px-4  border border-solid ${idx === 0 ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}
                                                                                    >{answer.answer}</div>
                                                                                </div>
                                                                            })}
                                                                        </div>
                                                                        {/* <div className="flex gap-5 justify-between mt-5 w-full text-base">
                                                        <div className="flex-auto">Totally Disagree</div>
                                                        <div className="flex-auto text-right">Totally Agree</div>
                                                    </div> */}
                                                                    </div> : null
                                                            }
                                                        </> :
                                                        <div className="max-w-[600px] w-full">
                                                            <div className="w-full">
                                                                <input className="grey-input-custom"/>
                                                            </div>
                                                        </div>
                                        }
                                    </div>
                                })}
                        </div>

                    </div> : null
            }
        </form>
    );
};
