import React, { useState, useEffect } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import signImg from "../assets/img/sign.png";
import SelectBox from '../components/base/SelectBox';
import InputField from '../components/base/InputField';
import Checkbox from '../components/base/Checkbox';
import PrimaryButton from '../components/base/PrimaryButton';
import { HiringProcess } from '../components/base/ProgressStepsSBM';
import ModalGrop from '../components/parts/ModalGrop';
import { Link, useParams } from 'react-router-dom';
import api from "../api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationByIdFetch, getJobByIdFetch } from '../redux/thunks/jobsThunks';

const TabButton = ({ onClick = () => { }, isActive, label, id }) => {
	return (
		<button
			type='button'
			onClick={() => onClick(id)}
			className={`flex items-center justify-center px-5 h-[46px] ${isActive ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
		>
			{label}
		</button>
	)
}

const SBM01_10_7 = () => {
	const dispatch = useDispatch();
	const { jobId, applicationId } = useParams();
	const { job, isLoading, application } = useSelector(state => state.jobs);

	const steps = [
		{ stepNumber: "1", label: "Clarifications", isActive: true },
		{ stepNumber: "2", label: "Video Interview", isActive: true },
		{ stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: true },
		{ stepNumber: "4", label: "2nd Interview", isActive: true },
		{ stepNumber: "5", label: "Document\nReview", isActive: true },
		{ stepNumber: "6", label: "Rating of\nThe Job", isActive: true },
		{ stepNumber: "7", label: "AI\nRecommendation", isActive: true },
		{ stepNumber: "8", label: "Final Hiring Decision", isActive: true },
		{ stepNumber: "9", label: "Training Insights", isActive: true },
		{ stepNumber: "10", label: "Offer Meeting", isActive: true },
		{ stepNumber: "11", label: "Training\nMilestones", isActive: false },
	];
	const tabsData = [
		{ label: 'Report Card', id: 1 },
		{ label: 'History', id: 2 },
		{ label: 'Reference', id: 3 },
		{ label: 'Reject', id: 4 },
		{ label: 'Offer Letter', id: 5 },
	];
	const [activeTab, setActiveTab] = useState(tabsData.length ? tabsData[0].id : null);
	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const initPayload = {
		interviewType: "test",
		duration: "Week",
		date: "2024-06-14",
		times: ["09:30", "10:30", "11:20"],
		employmentType: "Full Time",
		startDate: "2024-06-14",
		payRate: 0,
		payPeriod: "Day",
		payFrequency: "Daily",
		recruterName: "Morty",
		offerLetter: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem actuantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugi t, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam , quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
		interviewerId: null
	};

	const errorFieldNames = {
		interviewType: "Interview Type",
		duration: "Duration",
		date: "Select Available Start Times",
		times: "Meeting Times",
		employmentType: "Employment Type",
		startDate: "Start Date",
		payRate: "Pay Rate",
		payPeriod: "Pay Period",
		payFrequency: "Pay Frequency",
		recruterName: "Recruiter Name",
		offerLetter: "Offer Letter",
		interviewerId: "Interviewer"
	};

	const [payload, setPayload] = useState(null);

	console.log("payload", payload)


	const saveOffer = async() => {
		let haveError = false;
			console.log("payload", payload)
		Object.keys(errorFieldNames).forEach((fieldName) => {
			if(fieldName === "payRate"){
				if(+payload[fieldName] === 0){
					haveError = true;
					toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if(fieldName === "interviewerId"){
				if(payload[fieldName] === null){
					// haveError = true;
					// toast.error(`${errorFieldNames[fieldName]} is required`);
				}
			} else if(!payload[fieldName]?.length) {
				haveError = true;
				toast.error(`${errorFieldNames[fieldName]} is required`);
			}
		});

		if(haveError){
			console.log('payload:', payload);
			return false;
		}

		let res = await api.jobApplications.createOfferMeetings(applicationId, payload);

		if(res?.success){
			toast.success(res?.message || 'Successfully')
		} else {
			toast.error(res?.message || 'Something went wrong')
		}
	}

	const getOffer = async() => {
		let res = await api.jobApplications.getOfferMeetings(applicationId);
		if(res?.data){
			setPayload(res.data);
		} else {
			setPayload(initPayload);
		}
	}

	useEffect(() => {
        if (!jobId || !applicationId) return;
        dispatch(getJobByIdFetch(jobId))
        dispatch(getApplicationByIdFetch({
			applicationId,
			jobId
		}));

		getOffer();
    }, [jobId, applicationId, dispatch])

	return (
		payload && application ? <>
			<BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Jobs', 'Applicants', "Candidates", application?.fullName, "Report Card", "Offer Meeting"]} />
			<div className='flex-auto'>
				<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
					<header className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
						<div>
							<h2 className="text-4xl font-bold leading-10 text-zinc-950">{ application?.fullName }</h2>
						</div>
						<div className="grid max-lg:grid-cols-3 lg:grid-flow-col lg:ml-auto max-lg:w-full gap-[10px] my-auto">
							<ModalGrop/>
						</div>
					</header>
					<nav className="flex overflow-auto w-full gap-5 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
						{tabsData.map(tab => (
							<TabButton
								key={tab.id}
								id={tab.id}
								label={tab.label}
								isActive={activeTab === tab.id}
								onClick={handleTabClick}
							/>
						))}
					</nav>
					<section className="flex w-full text-center overflow-auto">
						<HiringProcess stepsData={steps} />
					</section>
					<div className='flex flex-col gap-11 lg:gap-[60px]'>
						<InterviewSetup
							payload={payload}
							setPayload={setPayload}
							application={application}
						/>
						<OfferLetterPage
							payload={payload}
							setPayload={setPayload}
							send={()=>{
								saveOffer();
							}}
						/>
						<section>
							<hr className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
							<div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
								<Link to={`/SBM01_10_6/${jobId}/${applicationId}`} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
									Back
								</Link>
								<Link to={`/SBM01_10_8/${jobId}/${applicationId}`} className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
									Next
								</Link>
							</div>
						</section>
					</div>
				</div>
			</div>
			<Footer
				hideCopyright={true}
			/>
		</> : null
	)
}

const InterviewSetup = ({ payload, setPayload, application }) => {
	const [outsideBusinessHours, setOutsideBusinessHours] = useState(false);
	const timeSlots = outsideBusinessHours ? [
		"12:00 am", "12:30 am", "01:00 am",
		"01:30 am", "02:00 am", "02:30 am",
		"03:00 am", "03:30 am", "04:00 am",
		"04:30 am", "05:00 am", "05:30 am",
		"06:00 am", "06:30 am", "07:00 am",
		"07:30 am", "08:00 am", "08:30 am",
		"09:00 am", "09:30 am", "10:00 am",
		"10:30 am", "11:00 am", "11:30 am",
		"12:00 pm", "12:30 pm", "01:00 pm",
		"01:30 pm", "02:00 pm", "02:30 pm",
		"03:00 pm", "03:30 pm", "04:00 pm",
		"04:30 pm", "05:00 pm", "05:30 pm",
		"06:00 pm", "06:30 pm", "07:00 pm",
		"07:30 pm", "08:00 pm", "08:30 pm",
		"09:00 pm", "09:30 pm", "10:00 pm",
		"10:30 pm", "11:00 pm", "11:30 pm",
	] : [
		"09:00 am", "09:30 am", "10:00 am",
		"10:30 am", "11:00 am", "11:30 am",
		"12:00 pm", "12:30 pm", "01:00 pm",
		"01:30 pm", "02:00 pm", "02:30 pm",
	];

	const [localPayload, setLocalPayload] = useState({...payload});

	const [confirmed, setConfirmed] = useState(true);

	const timeFrom12To24Format = (time) => {
		var hours = Number(time.match(/^(\d+)/)[1]);
		var minutes = Number(time.match(/:(\d+)/)[1]);
		var AMPM = time.match(/\s(.*)$/)[1];
		if(AMPM == "pm" && hours<12) hours = hours+12;
		if(AMPM == "am" && hours==12) hours = hours-12;
		var sHours = hours.toString();
		var sMinutes = minutes.toString();
		if(hours<10) sHours = "0" + sHours;
		if(minutes<10) sMinutes = "0" + sMinutes;
		return sHours + ":" + sMinutes;
	}

	useEffect(() => {
		setConfirmed(false);
	}, [localPayload]);

	return (
		<main className="flex flex-col py-9 px-8 lg:p-14 rounded-md border border-solid border-zinc-300">
			<section className="max-md:max-w-full">
				<header className="flex gap-5 max-md:flex-col max-md:gap-0">
					<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
						<section className="flex flex-col justify-center max-md:max-w-full">
							<h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">Finalize the interview details</h2>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interview Type</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={[
									{ label: 'test', value: 'test' },
									{ label: 'test 2', value: 'test 2' }
								]}
								svg={2}
								value={localPayload.interviewType}
								onSelect={(value)=>{
									setLocalPayload((prev)=>({
										...prev,
										interviewType: value
									}))
								}}
							/>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Duration</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={[
									{ label: 'Day', value: 'Day' },
									{ label: 'Week', value: 'Week' },
									{ label: 'Month', value: 'Month' },
								]}
								svg={2}
								value={localPayload.duration}
								onSelect={(value)=>{
									setLocalPayload((prev)=>({
										...prev,
										duration: value
									}))
								}}
							/>
							<h3 className="lg:mt-8 mt-4 text-lg font-semibold text-zinc-800 max-md:max-w-full">Interviewer</h3>
							<SelectBox
								className='mt-[16px] rounded-none'
								options={[
									{ label: 'test', value: null },
									{ label: 'test 1', value: 'test 1' },
									{ label: 'test 2', value: 'test 2' }
								]}
								svg={2}
								value={localPayload.interviewerId}
								onSelect={(value)=>{
									setLocalPayload((prev)=>({
										...prev,
										interviewerId: value
									}))
								}}
							/>
						</section>
					</div>
					<div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
						<section className="flex flex-col grow justify-center text-lg text-stone-500 max-md:mt-10 max-md:max-w-full">
							<h2 className="text-xl font-bold text-zinc-800 max-md:max-w-full">
								<span className='font-normal'>Calendar for:</span> { application?.fullName }
							</h2>
							<h3 className="mt-8 mb-[16px] font-semibold text-zinc-800 max-md:max-w-full">Select available start times</h3>
							<InputField
								type='date' inputBg='bg-white border border-solid border-[#DEDED9]'
								value={localPayload.date}
								onChange={(e)=>{
									setLocalPayload((prev)=>({
										...prev,
										date: e.target.value
									}))
								}}
							/>
							<div className="flex gap-4 items-center self-start p-1 mt-[14px] whitespace-nowrap">
								<div className="shrink-0 w-3 h-3 bg-green-500 rounded-[360px]"></div>
								<div className="grow">30 Minute Meeting</div>
							</div>
							<div className="flex gap-[22px] lg:gap-5 justify-between mt-[22px] lg:mt-6 w-full max-md:flex-wrap max-md:max-w-full">
								<div className="flex gap-3 whitespace-nowrap">
									<Checkbox
										checked={outsideBusinessHours}
										onChange={(value)=> {
											setOutsideBusinessHours(value);
										}}
									/>
									<div className="grow my-auto">Show outside ‘business hours’</div>
								</div>
								<div className="flex gap-3">
									<Checkbox
										checked={timeSlots.length === localPayload.times?.length}
										onChange={(value)=> {
											setLocalPayload((prev)=>({
												...prev,
												times: value ? timeSlots.map(time => timeFrom12To24Format(time)) : []
											}));
										}}
									/>
									<div className="my-auto">Select All</div>
								</div>
							</div>
							<div className="grid grid-cols-2 lg:grid-cols-4 gap-[13px] mt-8 whitespace-nowrap text-neutral-400 max-md:flex-wrap">
								{ timeSlots.map((time, index) => (
									<a
										href="#"
										className={`h-[54px] flex items-center justify-center bg-white border border-solid border-zinc-300 max-md:px-5 ${localPayload?.times.includes(timeFrom12To24Format(time)) ? '!bg-[#37B34A] !text-[#fff]' : ''}`}
										key={index} time={time}
										onClick={(e)=>{
											e.preventDefault();
											setLocalPayload((prev)=>({
												...prev,
												times: prev.times.includes(timeFrom12To24Format(time)) ? [...prev.times].filter(item => item !== timeFrom12To24Format(time)) : [...prev.times, timeFrom12To24Format(time)]
											}))
										}}
									>
										{time}
									</a>
								))}
							</div>
						</section>
					</div>
				</header>
			</section>
			<footer className="flex flex-col items-end pt-8 lg:pl-16 mt-8 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-zinc-300 w-full">
				<PrimaryButton
					className={`w-full lg:w-[120px] !h-[52px] !text-lg ${confirmed ? 'disabled' : ''}`}
					onClick={(e)=>{
						e.preventDefault();
						setPayload(localPayload);
						setConfirmed(true);
					}}
				>Confirm</PrimaryButton>
			</footer>
		</main>
	);
};

const employmentTypes = [
	{ label: 'Full Time', value: 'Full Time' },
	{ label: 'Part Time', value: 'Part Time' },
	{ label: 'Contract', value: 'Contract' },
];

const payPeriods = [
	{ label: 'Hour', value: 'Hour' },
	{ label: 'Day', value: 'Day' },
	{ label: 'Week', value: 'Week' },
	{ label: 'Month', value: 'Month' }
];

const payFrequencies = [
	{ label: 'Daily', value: 'Daily' },
	{ label: 'Weekly', value: 'Weekly' },
	{ label: 'Biweekly', value: 'Biweekly' },
	{ label: 'Monthly', value: 'Monthly' }
];


function OfferLetterPage({ payload, setPayload, send }) {
	return (
		<div className="flex gap-12 max-md:flex-col max-md:gap-0">
			<aside className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
				<div className="flex flex-col px-8 lg:py-14 py-9 text-lg rounded-md border border-solid border-zinc-300">
					<h2 className="text-xl font-bold text-zinc-800">Employment Type</h2>
					<SelectBox
						options={employmentTypes}
						className="mt-4 !h-[54px]"
						value={payload.employmentType}
						onSelect={(value)=>{
							setPayload((prev)=>({
								...prev,
								employmentType: value
							}))
						}}
					/>

					<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

					<h2 className="mt-8 text-xl font-bold text-zinc-800">Start Date</h2>
					<InputField
						type='date' inputBg='bg-white border border-solid border-[#DEDED9] mt-3.5'
						value={payload.startDate}
						onChange={(e)=>{
							setPayload((prev)=>({
								...prev,
								startDate: e.target.value
							}))
						}}
					/>

					<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

					<h2 className="mt-8 text-xl font-bold text-zinc-800">Compensation</h2>
					<div className="flex gap-3.5 justify-center mt-8">
						<div className="flex flex-col flex-1 justify-center">
							<div className="text-neutral-500">Pay Rate</div>
							<InputField
								inputBg='bg-white border border-solid border-[#DEDED9]'
								value={payload.payRate}
								onInput={(e)=>{
									setPayload((prev)=>({
										...prev,
										payRate: e.target.value
									}))
								}}
							/>
						</div>
						<div className="mb-[14px] flex items-center lg:mb-[18px] h-[54px] mt-auto text-neutral-500">per</div>
						<div className="flex flex-col flex-1 justify-center">
							<div className="text-neutral-500">Pay Period</div>
							<SelectBox
								options={payPeriods}
								className="mb-[18px] !h-[54px]"
								value={payload.payPeriod}
								onSelect={(value)=>{
									setPayload((prev)=>({
										...prev,
										payPeriod: value
									}))
								}}
							/>
						</div>
					</div>

					<div className="text-neutral-500">Pay Frequency</div>
					<SelectBox
						options={payFrequencies}
						className="mt-3.5 !h-[54px]"
						value={payload.payFrequency}
						onSelect={(value)=>{
							setPayload((prev)=>({
								...prev,
								payFrequency: value
							}))
						}}
					/>


					<div className="mt-4 lg:mt-6 text-neutral-500">Recruiter Name</div>
					<SelectBox
						options={[{ label: 'Marc Spector', value: 'Marc Spector' }, { label: 'Morty', value: 'Morty' }]}
						className="mt-3.5 !h-[54px]"
						value={payload.recruterName}
						onSelect={(value)=>{
							setPayload((prev)=>({
								...prev,
								recruterName: value
							}))
						}}
					/>

					<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

					<button
						className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
						onClick={(e)=>{
							e.preventDefault();
							send();
						}}
					>
						Save Offer Letter
					</button>
					<button
						className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-3.5 font-bold text-center text-white whitespace-nowrap bg-sky-900 rounded-md max-md:px-5"
					>
						Email Offer Letter
					</button>
				</div>
			</aside>

			<main className="flex flex-col lg:w-[70%] w-full max-lg:mt-11">
				<div className="flex flex-col grow self-stretch w-full">
					<h1 className="text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Offer Letter</h1>
					<article className="flex flex-col lg:px-9 lg:py-10 p-[22px] mt-5 text-lg rounded-md border border-solid bg-neutral-100 border-zinc-300 w-full flex-1">
						<p
							className="leading-8 text-stone-500 max-md:max-w-full" contentEditable
							onInput={(e)=>{
								setPayload((prev)=>({
									...prev,
									offerLetter: e.target.innerText
								}))
							}}
						>
							{ payload.offerLetter }
						</p>
						{/* <div className="flex gap-5 max-lg:text-center justify-between mt-2.5 font-bold leading-[167%] text-zinc-800 max-lg:flex-col w-full">
							<div className="flex-auto self-end mt-32 max-md:mt-10">Company Representative Signature</div>
							<div className='mt-5 aspect-[2.94] w-[206px]'>

							</div>
							<div className="flex flex-col justify-center items-center pb-3 whitespace-nowrap">
								<div>Marc Spector</div>
								<img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1258e57f53b63edd652cc0fc86ee4fab71d967d8da1ca39e33f3c7782e5548ae?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&" alt="Candidate signature" className="mt-5 aspect-[2.94] w-[206px]" />
								<div className="mt-5">Candidate</div>
							</div>
						</div> */}
					</article>
				</div>
			</main>
		</div>

	);
}

export default SBM01_10_7
