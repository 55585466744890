import React from 'react'

const CheckBoxBlueStyle = ({ text, checked, disabled,defaultChecked, whiteStyle=false, ...props }) => {
	return (
		<label className='custom-checkbox-blue cursor-pointer'>
			<input type="checkbox"
				className='hidden'
				checked={checked}
				defaultChecked={defaultChecked}
				disabled={disabled}
				{...props}
			/>
			<span className={`fake-check w-[26px] h-[26px] min-w-[26px] rounded-full bg-[#0056B3] ${whiteStyle ? 'bg-white text-[#0056B3] border border-[#0056B3]' : ''}`}></span>
			<span className='text-[22px] font-semibold leading-[24px] max-md:text-[18px]'>{text}</span>
		</label>
	)
}

export default CheckBoxBlueStyle
