
export const YesNoQuestion = ({ config, value, onChange, number, error, disabled, labelClassName = '', editedQuestions }) => {

    return <div className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
        <div
            className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${labelClassName} ${error ? 'text-red-600' : null}`}
        >
            {number ? number + '. ' : null}
            {editedQuestions?.[config?.id] || config.question}
            {config.isRequired && <span className="text-teal-500">*</span>}
        </div>
        <div className="flex items-center justify-between gap-[16px]">

            <label
                className="cursor-pointer custopm-radio flex items-center font-medium"
            >
                <input
                    type="radio"
                    className="radio-green-sub aspect-square w-5 mr-[10px]"
                    name={config.id}
                    onChange={() => onChange && onChange('no')}
                    checked={value === 'no'}
                    hidden
                    disabled={disabled}
                />
                <span className="check-box-fake mr-[12px]"></span>
                <p>No</p>
            </label>

            <label
                className="cursor-pointer custopm-radio flex items-center font-medium"
            >
                <input
                    type="radio"
                    className="radio-green-sub aspect-square w-5 mr-[10px]"
                    name={config.id}
                    onChange={() => onChange && onChange('yes')}
                    checked={value === 'yes'}
                    hidden
                />
                <span className="check-box-fake mr-[12px]"></span>
                <p>Yes</p>
            </label>

        </div>
    </div>
}
