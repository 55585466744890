import {useState} from "react";

export const getTotallyLabel = (id) => {
    if (id === 29) {
        return ["Totally Uncomfortable", "Totally Comfortable"]
    }
    if (id === 143) {
        return ["Totally Prefer to Work in a Team", "Totally Prefer to Work Solo"]
    }
    if (id === 145 || id === 14) {
        return ["Totally Unimportant", "Totally Important"]
    }

    return ["Totally Disagree", "Totally Agree"]
};


export const RangeQuestion = ({config, number, value, onChange, error, labelClassName = '', disabled, editedQuestions}) => {
    const answerTemplate = JSON.parse(config.answerTemplate);
    return <div className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
        <div
            className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${error ? 'text-red-600' : null} ${labelClassName}`}>
            {number && number + '. '}
            {editedQuestions?.[config?.id] || config.question}
            {config.isRequired && <span className="text-teal-500">*</span>}
        </div>
        <FitScaleEvaluationTable value={value} maxOptions={answerTemplate.max} disabled={disabled} onChange={onChange}
                                 labels={getTotallyLabel(config.id)}/>
    </div>
}

const FitScaleEvaluationTable = ({value = 1, onChange, maxOptions = 6, disabled, labels}) => {
    const createRatingScale = (selectedIdx) => Array.from({length: maxOptions}).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        if (!onChange) return
        setSelectedRatings(createRatingScale(index));
        onChange(index)
    };

    return (
        <div className="flex flex-col w-full">
            <div
                className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={disabled ? () => {
                        } : () => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4  border border-solid ${isSelected ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">{labels[0]}</div>
                <div className="flex-auto text-right">{labels[1]}</div>
            </div>
        </div>
    );
}
