import classNames from "classnames";
import {toast} from "react-toastify";
import {format} from 'date-fns'

export const EvaluationInterview = ({config, transcript, hideTotal, totalOnly, propsTotal = null}) => {
    const initialScores = Object.values(config);
    const total = initialScores.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue.score),
        0,
    ) / initialScores?.length || 0;


    function calculatePercentage(value, min = 0, max = 10) {
        if (value < min || value > max) {
            toast.error("Value must be between " + min + " and " + max);
        }
        return ((value - min) / (max - min)) * 100;
    }

    function parseTranscription(str) {
        if (typeof str !== "string") return;
        const regex = /(AI|User):/g;
        const parts = str.split(regex).filter(part => part.trim() !== '');

        // Преобразуем части в массив объектов
        const messages = [];
        for (let i = 0; i < parts.length; i += 2) {
            messages.push({
                sender: parts[i].trim(),
                message: parts[i + 1].trim()
            });
        }

        return messages;
    }

    function parseTranscriptionArray(transcript) {
        if (!Array.isArray(transcript)) return;

        const formatTimestamp = (isoString) => {
            try {
                return format(new Date(isoString), 'MM-dd-yyyy h:mma').toLowerCase() // Формат з маленькими літерами для 'am/pm'
            } catch (error) {
                console.error('Invalid date:', isoString)
                return ''
            }
        }

        const messages = transcript.map((entry) => ({
            sender: entry.role === 'assistant' ? 'AI' : 'User',
            message: entry.content.trim(),
            timestamp: formatTimestamp(entry.timestamp),
        }))

        return messages;
    }


    const messages = Array.isArray(transcript) ? parseTranscriptionArray(transcript) : parseTranscription(transcript)

    if (!initialScores.length) return


    console.log("messages", messages)

    return (
        <main className="flex flex-col items-start text-lg">
            {
                messages?.length && (
                    <>
                        <hr className="my-6 bg-neutral-100 h-1 w-full"/>
                        <div className="flex flex-col gap-4">
                            <h4 className="font-bold text-2xl">Transcriptions</h4>
                            {
                                messages.map((message, index) => (
                                    <div key={index}
                                         className={classNames("flex flex-col gap-1 px-4 py-2 bg-neutral-100 rounded-md w-full max-w-[80%]", {"!bg-[#37B34A] text-white ml-auto": message.sender.toLowerCase() === "user"})}>
                                        <p className=" flex justify-between">
                                            <span className="text-base font-bold"> {message.sender}</span>
                                        </p>
                                        <p className="text-lg relative">
                                            {message.message}
                                        </p>
                                        <p className="text-lg relative text-right">
                                            {message.timestamp || "N/A"}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
            {
                !hideTotal && (
                    <section className="flex gap-5 max-w-full whitespace-nowrap text-zinc-800 w-[400px]">
                        <TotalScore title={"Total Score"} score={(propsTotal / 10) || total?.toFixed() || 0}/>
                    </section>
                )
            }
            {
                !totalOnly && (
                    <div className='flex justify-between max-w-[900px]'>
                        <section
                            className="flex flex-wrap gap-y-6 gap-6 lg:gap-x-[100px] max-w-[900px] mt-7 max-md:mt-[16px] w-full text-zinc-800 max-md:gap-y-[16px]">
                            {initialScores.map((item, index) => (
                                <ScoreCard
                                    category={item?.name}
                                    score={calculatePercentage(item?.score) || 0}
                                    index={index}
                                    key={index}
                                />
                            ))}
                        </section>
                    </div>
                )
            }
        </main>
    );
};

const TotalScore = ({title, score}) => (
    <div className="flex gap-5 md:mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
        <div className="grow justify-center items-center px-16 py-4 font-semibold bg-neutral-100 w-fit max-md:px-5">
            {title}
        </div>
        <div
            className="flex gap-2 cursor-default justify-center px-5 py-4 min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5"
        >
            <div>{score * 10}%</div>
        </div>
    </div>
);


const ScoreCard = ({category, score}) => (
    <div className="flex gap-5 justify-between max-w-[400px] w-full">
        <label className="my-auto font-bold">{category}</label>
        <input
            type="number"
            min={0}
            disabled
            readOnly
            value={parseInt(score)}
            className="flex gap-2 justify-center px-5 py-4 w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5"
        />
    </div>
);
