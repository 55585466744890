const base = 'api/job-video-interview';
const applicationBase = 'api/job-application-video-interview'

export const jobVideoInterview = (instance) => ({
    async createVideoInterview(payload) {
        const { data } = await instance.post(`${base}/${payload.jobId}`, payload.body);
        return data;
    },
    async updateVideoInterview(payload) {
        const { data } = await instance.put(`${base}/${payload.id}/${payload.jobId}`, payload.body);
        return data;
    },
    async getVideoInterview(payload) {
        const { data } = await instance.get(`${base}/${payload.jobId}?type=${payload.type}`);
        return data;
    },
    async getVideoInterviewTemplates() {
        const { data } = await instance.get(`${base}/templates`);
        return data;
    },
    async getVideoInterviewPreset(payload) {
        const { data } = await instance.get(`${base}/templates/${payload.jobId}/${payload.jobVideoInterviewId}`);
        return data;
    },
    async cloneVideoInterview(payload) {
        const { data } = await instance.post(`${base}/templates/clone/${payload.jobId}/${payload.jobVideoInterviewId}`);
        return data;
    },
    async getListVideoInterview(applicationId) {
        const { data } = await instance.get(`${applicationBase}/all/${applicationId}`);
        return data;
    },
    async sendVideoInterview(payload) {
        const { data } = await instance.get(`${applicationBase}/send-questions/${payload.applicationId}/${payload.interviewId}`);
        return data;
    },
    async getInterviewData(payload) {
        const { data } = await instance.get(`${applicationBase}/public/${payload.jobApplicationId}/${payload.candidateId}`);
        return data;
    },
    async getInterviewDetails(payload) {
        const { data } = await instance.get(`${applicationBase}/${payload.jobApplicationId}/${payload.interviewId}`);
        return data;
    },
    async getInterviewQuestions(locationName) {
        const { data } = await instance.get(`api/quantitative-analysis/?locationName=${locationName}&no_limit=true`);
        return data;
    },
});
