import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import PageLoader from '../components/PageLoader';
import { Footer } from '../components/parts/Footer';
import { USER_TYPES } from '../data/enums';
import { getSubscriberRole } from '../redux/slices/applicationSlice';
import { getApplicationByIdFetch, getJobByIdFetch } from '../redux/thunks/jobsThunks';
import { TabsSBM01 } from './SBM01_Form/base/TabsSBM01';
import { crumbsConfig } from './SBM01_Form/config';

export const calcTotalPercent = (scores, criticalRequirements = null) => {
    const statuses = [
        "Completed",
        "A Score",
        "On-going",
        "Not Started",
    ];

    const data = {
        percentage: 0,
        status: "Not Started",
        completed: false
    };

    if (typeof scores === 'object' && !Array.isArray(scores)) {
        data.percentage = 100;
        data.completed = true;
        data.status = "Completed";
        return data;
    }

    const totalQuestions = criticalRequirements?.length || scores?.length || 0;
    const answeredQuestions = scores?.filter(item => !isNaN(parseInt(item)))?.length || 0;

    if (totalQuestions > 0) {
        data.percentage = Math.floor((answeredQuestions / totalQuestions) * 100);
    }

    data.completed = data.percentage === 100;
    data.status = data.completed ? "Completed" : answeredQuestions > 0 ? "On-going" : "Not Started";

    return data;
};
export const calculateTotalScore = (clarifications, answersScores, maxScore = 7) => {
    const statuses = [
        "Completed",
        "On-going",
        "Not Started",
    ];

    if (!clarifications?.length || !answersScores?.length) {
        return {
            total: {
                status: "Not Started",
            }
        };
    }
    const totalQuestions = clarifications.length;

    const totalQuestionsAnswered = answersScores.filter(score => !isNaN(parseFloat(score))).length;

    const totalScoreSum = answersScores.reduce((sum, score) => !isNaN(parseFloat(score)) ? sum + parseFloat(score) : sum, 0);

    const maxPossibleTotalScore = totalQuestionsAnswered * maxScore;

    const totalPercentage = totalQuestionsAnswered > 0
        ? Math.round((totalScoreSum / maxPossibleTotalScore) * 100)
        : 0;

    let totalStatus = statuses[2];
    if (totalQuestionsAnswered === totalQuestions) {
        totalStatus = statuses[0];
    } else if (totalQuestionsAnswered > 0) {
        totalStatus = statuses[1];
    }

    return {
        total: {
            score: totalScoreSum,
            percentage: totalPercentage,
            status: totalStatus,
            name: "Total",
            key: "total"
        }
    };
};
export const generateReportCardData = (pipelines, jobId, applicationId, application, stageMapping, role) => {
    const stages = JSON.parse(pipelines);

    if(stages?.length) {
        const index = stages.indexOf("ai_evaluation");
        stages.splice(index, 0, "scores")
    }


    return ["сlarification", ...stages]?.map(stageKey => {

        const stage = stageMapping[stageKey.toLowerCase().replace("-", "_")];

        if (!stage) return null;

        if (stage.forbidden?.includes(role)) {
            return null
        }


        return {
            title: stage.title,
            borderColor: stage.borderColor,
            linkTo: stage?.linkTo(jobId, applicationId),
            percentage: stage?.total?.percentage || stage?.percentage,
            status: stage?.total?.status || stage.status,
            forbidden: stage.forbidden
        };
    }).filter(Boolean);
};

const SBM01_10_14_2 = () => {
    const dispatch = useDispatch();
    const { jobId, applicationId } = useParams();
    const { job, isLoading, application } = useSelector(state => state.jobs);
    const subscriberRole = useSelector(getSubscriberRole);
    const [reportCardData, setReportCardData] = useState(null);

    useEffect(() => {
        if (jobId && applicationId) {
            dispatch(getJobByIdFetch(jobId));
            dispatch(getApplicationByIdFetch({ applicationId, jobId }));
        }
    }, [jobId, applicationId]);

    const crumbs = [crumbsConfig.sub, crumbsConfig.jobs, ['Applicants', `/SBM01_2/${jobId}?tab=applicants`], ["Candidates", `/SBM01_2/${jobId}?tab=candidates`], [application?.fullName, `/SBM01_2/${jobId}?tab=candidates`], "Report Card"];

    const stageMapping = {
        "сlarification": {
            title: "Clarification",
            borderColor: "border-sky-900",
            ...calculateTotalScore(application?.criticalRequirements?.clarifications, application?.assessments?.["clarifications"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_18/${jobId}/${applicationId}`,
        },
        "1st_interview": {
            title: "Video Interview",
            borderColor: "border-gray-200",
            ...calculateTotalScore(application?.criticalRequirements?.["1st-interview"], application?.assessments?.["1st-interview"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_1/${jobId}/${applicationId}`,
        },
        "post_interview_survey": {
            title: "Post Interview Survey",
            borderColor: "border-gray-200",
            ...calculateTotalScore(application?.criticalRequirements?.["post_interview_survey"], application?.assessments?.["post_interview_survey"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_2/${jobId}/${applicationId}`,
        },
        "2nd_interview": {
            title: job?.pipeline?.id === 1 ? "In Person Interview" : "Virtual Interview",
            borderColor: "border-gray-200",
            ...calculateTotalScore(application?.criticalRequirements?.["2nd-interview"], application?.assessments?.["2nd-interview"]?.scores
            ),
            linkTo: (jobId, applicationId) => `/SBM01_10_3/${jobId}/${applicationId}`,
        },
        "rating_of_the_job": {
            title: "Rating of the Job",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["recuterInput"]),
            linkTo: (jobId, applicationId) => `/SBM01_10_16/${jobId}/${applicationId}`,
        },
        "document_review": {
            title: "Document Review",
            ...calcTotalPercent(application?.assessments?.["document-review"]?.scores),
            borderColor: "border-gray-200",
            linkTo: (jobId, applicationId) => `/SBM01_10_4/${jobId}/${applicationId}`
        },
        "scores": {
            title: "Scores",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["scores"]?.scores),
            linkTo: (jobId, applicationId) => `/scores/${jobId}/${applicationId}`,
        },
        "ai_evaluation": {
            title: "AI Evaluation",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["ai-recomendations"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_5/${jobId}/${applicationId}`,
        },
        "final_hiring_decisions": {
            title: "Final Hiring Decisions",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["final-decisions"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_5_5/${jobId}/${applicationId}`,
            forbidden: [USER_TYPES.committee]
        },
        "offer_meeting": {
            title: "Offer Meeting",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["offer"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_7/${jobId}/${applicationId}`,
            forbidden: [USER_TYPES.committee]

        },
        "training_insights": {
            title: "Training Insights",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["insights"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_6/${jobId}/${applicationId}`,
            forbidden: [USER_TYPES.committee]

        },
        "training_milestones": {
            title: "Training Milestones",
            borderColor: "border-gray-200",
            ...calcTotalPercent(application?.assessments?.["milestones"]?.scores),
            linkTo: (jobId, applicationId) => `/SBM01_10_8/${jobId}/${applicationId}`,
            forbidden: [USER_TYPES.committee]

        },
    };

    useEffect(() => {
        if (job?.pipeline?.pipelines && application) {
            const data = generateReportCardData(job?.pipeline?.pipelines, jobId, applicationId, application, stageMapping, subscriberRole);
            setReportCardData(data);
        }
    }, [job, application]);

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <>
            {job && application ? (
                <>
                    <BreadCrumbWhite crumbs={crumbs} />
                    <div
                        className='max-w-[1432px] flex-auto px-4 w-full pt-10 pb-20 lg:pt-[60px] lg:pb-[90px] mx-auto'>
                        <p className="text-lg font-semibold text-sky-900">{job.jobTitle} / Candidates</p>
                        <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">
                            {application.fullName}
                        </h2>
                        <TabsSBM01 activeLink={'report-card'} />
                        <h2 className='my-9 lg:my-[60px] text-[28px] lg:text-[30px] font-bold text-[#333333]'>Report
                            Card</h2>
                        <div className='flex items-center gap-3 lg:gap-[18px] max-lg:mb-4'>
                            <p className='lg:text-2xl text-lg font-bold text-[#37B34A]'>{application.fullName}</p>
                            <p className='text-base lg:text-lg font-normal text-[#666666]'>Applied
                                Date {format(application.createdAt, 'MM/dd/yyyy')}</p>
                        </div>
                        <div
                            className='flex items-center overflow-auto gap-[2px] border-b border-solid border-[#DEDED9]'>
                            {reportCardData?.map((item, index) => (
                                <div className='flex-1 flex flex-col items-center min-w-[150px]' key={index}>
                                    <div
                                        className={classNames('w-full h-[90px] flex items-center flex-col gap-[6px] justify-center border-b-[5px] border-solid', item.completed ? 'border-[#003578]' : 'border-[#EBE9EA]')}>
                                        <Link to={item.linkTo}
                                            className='text-base font-bold text-[#37B34A] max-w-[150px] line-clamp-2 text-center'>{item.title}</Link>
                                        <p className='text-base font-normal text-[#777777]'>{item.percentage ? item.percentage + " %" : 'Send'}</p>
                                    </div>
                                    <div className='h-[90px] flex items-center justify-center'>
                                        <p className='text-base font-normal text-[#777777]'>{item.status} {item.progress &&
                                            <span className='text-[#37B34A]'>({item.progress})</span>}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <h2 className="text-4xl font-bold text-center my-12">Not found</h2>
            )}
            <Footer hideCopyright={true} />
        </>
    );
}

export default SBM01_10_14_2;
