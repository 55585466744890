import {useSelector} from "react-redux";
import {HiringProcess} from "../../../components/base/ProgressStepsSBM";
import React, {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import {getSubscriberRole} from "../../../redux/slices/applicationSlice";
import {USER_TYPES} from "../../../data/enums";
import {calcTotalPercent, calculateTotalScore, generateReportCardData} from "../../SBM01_10_14(2)";

const defaultSteps = (jobId, applicationId) => [
    {stepNumber: "1", label: "Clarifications", link: `/SBM01_10_18/${jobId}/${applicationId}`},
    {stepNumber: "2", label: "Video Interview", link: `/SBM01_10_1/${jobId}/${applicationId}`},
    {stepNumber: "3", label: "Post 1st Interview\nSurvey", link: `/SBM01_10_2/${jobId}/${applicationId}`},
    {stepNumber: "4", label: "2nd Interview", link: `/SBM01_10_3/${jobId}/${applicationId}`},
    {stepNumber: "5", label: "Document\nReview", link: `/SBM01_10_4/${jobId}/${applicationId}`},
    {stepNumber: "6", label: "Rating of\nThe Job", link: `/SBM01_10_16/${jobId}/${applicationId}`},
    {stepNumber: "7", label: "Scores", link: `/scores/${jobId}/${applicationId}`},
    {stepNumber: "8", label: "AI\nRecommendation", link: `/SBM01_10_5/${jobId}/${applicationId}`},
    {stepNumber: "9", label: "Final Hiring Decision", link: `/SBM01_10_5_5/${jobId}/${applicationId}`},
    {stepNumber: "10", label: "Training Insights", link: `/SBM01_10_6/${jobId}/${applicationId}`},
    {stepNumber: "11", label: "Offer Meeting", link: `/SBM01_10_7/${jobId}/${applicationId}`},
    {stepNumber: "12", label: "Training\nMilestones", link: `/SBM01_10_8/${jobId}/${applicationId}`},
];

const pipelineMapping = (jobId, applicationId, pipelineId) => ({
    "сlarification": {label: "Clarifications", link: `/SBM01_10_18/${jobId}/${applicationId}`},
    "1st_Interview": {label: "Video Interview", link: `/SBM01_10_1/${jobId}/${applicationId}`},
    "post_interview_survey": {label: "Post Interview\nSurvey", link: `/SBM01_10_2/${jobId}/${applicationId}`},
    "2nd_interview": {
        label: pipelineId === 1 ? "In Person Interview" : "Virtual Interview",
        link: `/SBM01_10_3/${jobId}/${applicationId}`
    },
    "document_review": {label: "Document Review", link: `/SBM01_10_4/${jobId}/${applicationId}`},
    "rating_of_the_job": {label: "Rating of\nThe Job", link: `/SBM01_10_16/${jobId}/${applicationId}`},
    "scores": {label: "Scores", link: `/scores/${jobId}/${applicationId}`},
    "ai_evaluation": {label: "AI\nRecommendation", link: `/SBM01_10_5/${jobId}/${applicationId}`},
    "final_hiring_decisions": {label: "Final Hiring Decision", link: `/SBM01_10_5_5/${jobId}/${applicationId}`},
    "offer_meeting": {label: "Offer Meeting", link: `/SBM01_10_7/${jobId}/${applicationId}`},
    "training_insights": {label: "Training Insights", link: `/SBM01_10_6/${jobId}/${applicationId}`},
    "training_milestones": {label: "Training\nMilestones", link: `/SBM01_10_8/${jobId}/${applicationId}`},
});

const getIsActiveStep = (locationPath, currentLink) => locationPath === currentLink;

const generateSteps = (pipelines, jobId, applicationId, pipelineId) => {
    let steps = [];
    const mapping = pipelineMapping(jobId, applicationId, pipelineId);

    if (!pipelines.find(one => one === "сlarification")) {
        pipelines = ["сlarification"].concat(pipelines);
        const index = pipelines.indexOf("ai_evaluation")
        pipelines.splice(index, 0, "scores");
    }

    let stepCounter = 1;
    pipelines.forEach((pipeline) => {
        const mappedStep = mapping[pipeline];
        if (mappedStep) {
            steps.push({
                stepNumber: stepCounter.toString(),
                label: mappedStep.label,
                link: mappedStep.link,
                id: pipeline
            });
            stepCounter++;
        }
    });

    return steps;
};

export const HiringProcessSBM01 = ({activeTab = 1, jobPipeline = null}) => {
    const subscriberRole = useSelector(getSubscriberRole);
    const [modifyStep, setModifyStep] = useState(null);
    const {jobId, applicationId} = useParams();
    const {job, application} = useSelector(state => state.jobs);

    const location = useLocation();

    useEffect(() => {
        let steps = [];
        const parsedPipelines = jobPipeline?.pipelines && JSON.parse(jobPipeline?.pipelines);


        if (parsedPipelines?.length) {
            steps = generateSteps(parsedPipelines, jobId, applicationId, jobPipeline.id);
        } else {
            steps = [...defaultSteps(jobId, applicationId)];
        }

        const modifiedSteps = steps.map((step) => ({
            ...step,
            isActive: getIsActiveStep(location.pathname, step.link),
        }));

        if (subscriberRole === USER_TYPES.committee && modifiedSteps.length) {
            const index = modifiedSteps.findIndex(({id}) => id === "ai_evaluation");
            setModifyStep(modifiedSteps.slice(0, index + 1));


        } else {
            setModifyStep(modifiedSteps);
        }
    }, [jobPipeline, subscriberRole, location.pathname, jobId, applicationId]);


    const stageMapping = {
        "сlarification": {
            title: "Clarification",
            ...calculateTotalScore(application?.criticalRequirements?.clarifications, application?.assessments?.["clarifications"]?.scores),
        },
        "1st_interview": {
            title: "Video Interview",
            ...calculateTotalScore(application?.criticalRequirements?.["1st-interview"], application?.assessments?.["1st-interview"]?.scores),
        },
        "post_interview_survey": {
            title: "Post Interview Survey",
            ...calculateTotalScore(application?.criticalRequirements?.["post_interview_survey"], application?.assessments?.["post_interview_survey"]?.scores),
        },
        "2nd_interview": {
            title: job?.pipeline?.id === 1 ? "In Person Interview" : "Virtual Interview",
            ...calculateTotalScore(application?.criticalRequirements?.["2nd-interview"], application?.assessments?.["2nd-interview"]?.scores),
        },
        "rating_of_the_job": {
            title: "Rating of the Job",
            ...calcTotalPercent(application?.assessments?.["recuterInput"]),
        },
        "document_review": {
            title: "Document Review",
            ...calcTotalPercent(application?.assessments?.["document-review"]?.scores),
        },
        "scores": {
            title: "Scores",
            ...calcTotalPercent(application?.assessments?.["scores"]?.scores),
        },
        "ai_evaluation": {
            title: "AI Evaluation",
            ...calcTotalPercent(application?.assessments?.["ai-recomendations"]?.scores),
        },
        "final_hiring_decisions": {
            title: "Final Hiring Decisions",
            ...calcTotalPercent(application?.assessments?.["final-decisions"]?.scores),
        },
        "offer_meeting": {
            title: "Offer Meeting",
            ...calcTotalPercent(application?.assessments?.["offer"]?.scores),

        },
        "training_insights": {
            title: "Training Insights",
            ...calcTotalPercent(application?.assessments?.["insights"]?.scores),

        },
        "training_milestones": {
            title: "Training Milestones",
            ...calcTotalPercent(application?.assessments?.["milestones"]?.scores),

        },
    };

    console.log("stageMapping", stageMapping)
    console.log("modifyStep", modifyStep)


    return (
        <section className="flex w-full text-center overflow-auto pt-5">
            {modifyStep && <HiringProcess stepsData={modifyStep} stageMapping={stageMapping}/>}
        </section>
    );
};


// import { useSelector } from "react-redux";
// import { HiringProcess } from "../../../components/base/ProgressStepsSBM";
// import React, { useEffect, useState } from "react";
// import { getSubscriberRole } from "../../../redux/slices/applicationSlice";
// import { useParams } from "react-router-dom";
//
// export const HiringProcessSBM01 = ({ activeTab = 1, pipelines=null }) => {
//     const subscriberRole = useSelector(getSubscriberRole)
//
//     const [modifyStep, setModifyStep] = useState(null)
//     const { jobId, applicationId } = useParams();
//
//     console.log("pipelines", pipelines)
//
//     const steps = [
//         { stepNumber: "1", label: "Clarifications", isActive: false, link: `/SBM01_10_18/${jobId}/${applicationId}` },
//         { stepNumber: "2", label: "1st Interview", isActive: false, link: `/SBM01_10_1/${jobId}/${applicationId}` },
//         { stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: false, link: `/SBM01_10_2/${jobId}/${applicationId}` },
//         { stepNumber: "4", label: "2nd Interview", isActive: false, link: `/SBM01_10_3/${jobId}/${applicationId}` },
//         { stepNumber: "5", label: "Document\nReview", isActive: false, link: `/SBM01_10_4/${jobId}/${applicationId}` },
//         { stepNumber: "6", label: "Rating of\nThe Job", isActive: false, link: `/SBM01_10_16/${jobId}/${applicationId}` },
//         { stepNumber: "7", label: "Scores", isActive: false, link: `/scores/${jobId}/${applicationId}` },
//         { stepNumber: "8", label: "AI\nRecommendation", isActive: false, link: `/SBM01_10_5/${jobId}/${applicationId}` },
//         { stepNumber: "9", label: "Final Hiring Decision", isActive: false, link: `/SBM01_10_5_5/${jobId}/${applicationId}` },
//         { stepNumber: "10", label: "Training Insights", isActive: false, link: `/SBM01_10_6/${jobId}/${applicationId}` },
//         { stepNumber: "11", label: "Offer Meeting", isActive: false, link: `/SBM01_10_7/${jobId}/${applicationId}` },
//         { stepNumber: "12", label: "Training\nMilestones", isActive: false, link: `/SBM01_10_8/${jobId}/${applicationId}` },
//     ];
//
//     useEffect(() => {
//         let modifiedStep = steps.map((step, index) => {
//             if (step.stepNumber <= activeTab) {
//                 step.isActive = true
//                 return step
//             } else {
//                 return step
//             }
//         })
//         if (subscriberRole === "interviewer" && modifiedStep.length) {
//             modifiedStep = modifiedStep.slice(0, 4)
//         }
//         setModifyStep(modifiedStep)
//     }, [activeTab]);
//
//     return (
//         <section className="flex w-full text-center overflow-auto">
//             {modifyStep && <HiringProcess stepsData={modifyStep} />}
//         </section>
//     )
// }
